//
// Component: Hand
//
// Displays and controls a player's hand
//

// dependencies
import React from 'react'
import { Box, Flex } from 'rebass'
import { observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import GameStore from 'App/stores/GameStore'

import Card from 'App/components/card/Card.js'

@observer
class Hand extends React.Component {
  //
  // props
  //
  // active - north, south, east, west - the hand that is in play
  // displayAsFan - true/false - display hand in fan style
  // dummy - true/false - your partner
  // hand - cards in PBN format
  // horizontalCards - true/false - orientation of cards
  // horizontalHand - true/false - orientation of hand
  // player - north, south, east, west - this player
  // showMarker - true/false - not sure what this is yet
  // visible - true/false - if false the back of the card will be shown
  // spacing - passed through to Card to define spacing between cards
  //           this will be different for a full hand and just one suit
  // showCloseMarker - true/false - whether to show the 'clsoe' button
  //                   if a single suit is shown

  constructor (props) {
    super(props)
    this.state = {
    }

    this.cardClicked = this.cardClicked.bind(this) // called after rendered so need to bind it to this component
    this.showAllCards = this.showAllCards.bind(this)
  }

  componentDidMount () {
    this.setState({
      displayCardHorizontal: this.isCardHorizontal()
    })
    this.setState({
      displayHandHorizontal: this.isHandHorizontal()
    })
    this.setState({
      isNorthorSouth: this.isPlayerNorthorSouth()
    })
  }

  cardClicked (hand, rank, suit) {
    // Card has been clicked & this has been passed up from Card
    // Therefore anything in here refers to the Card not Hand
    // so this will not work for Hand functions
    //
    // However, bind it in the constructor and it works

    this.props.cardClicked(hand, rank, suit)
  }

  showAllCards () {
    this.props.clickCloseMarker()
  }

  isCardHorizontal () {
    if ((this.props.player === 'east' || this.props.player === 'west') && !this.props.dummy) {
      return true
    } else {
      return false
    }
  }

  isPlayerNorthorSouth () {
    if (this.props.player === 'north' || this.props.player === 'south') {
      return true
    } else {
      return false
    }
  }

  isHandHorizontal () {
    if ((this.props.player === 'north' || this.props.player === 'south')) {
      return true
    } else {
      return false
    }
  }
  //
  // FUNCTION: renderHand
  //
  // Builds a Hand of Card components
  // Note that this function has been partially copied
  // from the BridgeJS library
  //
  // INPUT: pbn - a hand in PBN notation
  //

  renderHand (pbn) {
    var rank
    var i
    var hand = []
    var dummyHand = []

    // pbnOrder - defines what the next suit is
    // eg. if Spades, next suit will be Hearts
    // var pbnOrder = {
    //   S: 'H',
    //   H: 'D',
    //   D: 'C',
    //   C: null
    // }

    var suit = 'H'
    var pbnOrder = {
      H: 'S',
      S: 'D',
      D: 'C',
      C: null
    }

    // if it's the dummy, make the trump suit the first card
    if (this.props.dummy && this.props.sessionStatus?.winningBidSuit) {
      if (this.props.sessionStatus.winningBidSuit !== 'notrump') {
        // reset the pbn order
        pbnOrder = {
          H: 'S',
          S: 'D',
          D: 'C',
          C: 'H'
        }

        suit = this.props.sessionStatus.winningBidSuit.charAt(0).toUpperCase()

        // build a map of the original dummy pbn
        var pbnParts = pbn.split('.')
        var pbnMap = {}
        for (const suit of ['H', 'S', 'D', 'C']) {
          pbnMap[suit] = pbnParts.shift()
        }

        // rebuild tbe pbn to match the new order of the dummy card
        var currentRebuildSuit = suit
        var newPbnParts = []
        while (newPbnParts.length < 4) {
          newPbnParts.push(pbnMap[currentRebuildSuit])
          currentRebuildSuit = pbnOrder[currentRebuildSuit]
        }
        pbn = newPbnParts.join('.')
      }
    }

    var cardCounter = 1
    if (pbn && pbn !== '-') {
      for (i = 0; i < pbn.length; ++i) {
        rank = pbn[i]
        if (rank === '.') {
          // Arrange hand if dummy hand
          if (this.props.dummy && !this.props.single) {
            // And dummy hand is in east or west position
            if (this.props.player === 'east' || this.props.player === 'west') {
              dummyHand.push(
                <Flex
                  sx={{
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignSelf: this.props.player === 'east' ? 'start' : 'end',
                    width: '100%'
                  }}
                >
                  <Box
                    id='hand'
                    className={'hand player-' + this.props.player + ' ' + ((this.props.active && (this.props.player === 'south' || this.props.player === 'north')) ? 'active-hand ' : '') + ' ' + (!this.state.isNorthorSouth ? 'hhand-compact ' : 'vhand-compact ')}
                  >
                    {hand}
                  </Box>
                </Flex>
              )
            } else {
              // Or dummy hand is in north or south position
              dummyHand.push(
                <Flex
                  sx={{
                    flexDirection: 'column',
                    justifyContent: this.props.player === 'east' ? 'start' : 'flex-end',
                    alignSelf: this.props.player === 'east' ? 'start' : 'end',
                    width: '100%'
                  }}
                >
                  <Box
                    id='hand'
                    className={'hand player-' + this.props.player + ' ' + ((this.props.active && (this.props.player === 'south' || this.props.player === 'north')) ? 'active-hand ' : '') + ' ' + (this.state.isNorthorSouth ? 'vhand-compact ' : 'hhand-compact ')}
                  >
                    {hand}
                  </Box>
                </Flex>
              )
            }
            hand = []
          }
          suit = pbnOrder[suit]
        } else {
          // console.log('hand issue - Trying to create,', this.props.player, suit, rank)
          hand.push(<Card dummy={this.props.dummy} key={this.props.player + cardCounter} spacing={this.props.spacing} id={this.props.player + cardCounter} hand={this.props.player} suit={suit} rank={rank} visible={(this.props.observer || ((GameStore.turnNumber > 0 || GameStore.trickNumber > 1) && this.props.dummy)) ? true : this.props.visible} horizontalCard={this.state.displayCardHorizontal} horizontalHand={this.state.displayHandHorizontal} cardClicked={this.cardClicked} />)
          cardCounter++
        }
      }

      // If dummy then the last suit still needs adding
      if (this.props.dummy && !this.props.single) {
        // And dummy hand is in east or west position
        if (this.props.player === 'east' || this.props.player === 'west') {
          dummyHand.push(
            <Flex
              sx={{
                flexDirection: 'row',
                justifyContent: this.props.player === 'east' ? 'start' : 'flex-start',
                alignSelf: this.props.player === 'east' ? 'start' : 'end',
                width: '100%'
              }}
            >
              <Box
                id='hand'
                className={'hand player-' + this.props.player + ' ' + ((this.props.active && (this.props.player === 'south' || this.props.player === 'north')) ? 'active-hand ' : '') + ' ' + (!this.state.isNorthorSouth ? 'hhand-compact ' : 'vhand-compact ')}
              >
                {hand}
              </Box>
            </Flex>
          )
        } else {
          // Or dummy hand is in north or south position
          dummyHand.push(
            <Flex
              sx={{
                flexDirection: 'column',
                justifyContent: this.props.player === 'east' ? 'start' : 'flex-end',
                alignSelf: this.props.player === 'east' ? 'start' : 'end',
                width: '100%'
              }}
            >
              <Box
                id='hand'
                className={'hand player-' + this.props.player + ' ' + ((this.props.active && (this.props.player === 'south' || this.props.player === 'north')) ? 'active-hand ' : '') + ' ' + (this.state.isNorthorSouth ? 'vhand-compact ' : 'hhand-compact ')}
              >
                {hand}
              </Box>
            </Flex>
          )
        }
      }

      if (this.props.dummy && !this.props.single) {
        hand = dummyHand
      }

      return hand
    }
  }

  render () {
    var hand = this.renderHand(this.props.hand)
    var handView

    if (this.props.dummy && !this.props.single) {
      handView = hand
    } else {
      handView =
        <Box
          id='hand'
          className={'hand player-' + this.props.player + ' ' + ((this.props.active && (this.props.player === 'south' || this.props.player === 'north')) ? 'active-hand ' : '') + ' ' + (this.props.horizontalHand ? 'hhand-compact ' : 'vhand-compact ')}
        >
          {hand}
        </Box>
    }

    // Arrange the hand of cards and player name correctly
    var marginTop
    var marginBottom
    var marginRight
    var marginLeft
    switch (this.props.player) {
      case 'north':
        if (this.props.dummy) {
          marginTop = [30, 30]
        } else {
          marginBottom = [10, 50]
        }
        break
      case 'east':
        marginLeft = [-10, -20]
        break
      case 'south':
        if (this.props.dummy) {
          marginTop = [30, 30]
        } else {
          marginTop = [0, 40]
        }
        break
      case 'west':
        marginRight = [-10, -20]
        break
    }

    const handContainer =
      <Flex
        sx={{
          flexDirection: this.props.dummy && (this.props.player === 'east' || this.props.player === 'west') ? 'column' : 'row',
          flex: this.props.dummy && (this.props.player === 'south' || this.props.player === 'north') ? 0.8 : 'auto',
          marginTop: marginTop,
          marginBottom: marginBottom,
          marginRight: marginRight,
          marginLeft: marginLeft
        }}
        className={'player-hand ' + (this.props.horizontalHand ? 'horizontal-hand' : 'vertical-hand') + ' player-relative-' + this.props.player}
      >
        {handView}
        {this.props.showCloseMarker &&
          <Box
            sx={{
              padding: 2,
              bg: '#492a2c',
              margin: 2,
              position: 'absolute',
              top: 0,
              right: '-30px',
              cursor: 'pointer'
            }}
            // eslint-disable-next-line react/jsx-handler-names
            onClick={this.showAllCards}
          >
            {/* eslint-disable-next-line react/jsx-handler-names */}
            <FontAwesomeIcon color='#fff' icon={['fas', 'times-circle']} />
          </Box>}
      </Flex>

    return (
      <Flex
        sx={{
          flex: this.props.dummy && (this.props.player === 'south' || this.props.player === 'north') ? 1 : 'auto',
          height: 'fit-content'
        }}
        className='player-hand-container'
      >
        {handContainer}
      </Flex>
    )
  }
}
export default Hand
