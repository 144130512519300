//
// Component: Game
//
// Displays and controls the game board
//

// dependencies
import React from 'react'
import Scrollbars from 'react-custom-scrollbars'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { get } from 'mobx'
import { observer } from 'mobx-react'
import { Box, Flex } from 'rebass'

// utilities
import { getQueryVariable } from 'App/utils/queryString'

// stores
import GameStore from '../stores/GameStore'
import AuthStore from 'App/stores/AuthStore.js'

// components
import Hand from 'App/components/hand/Hand.js'
import NavBar from 'App/components/navbar/NavBar'
import PlayerName from 'App/components/playerName/PlayerName.js'
import TableCards from 'App/components/table/TableCards.js'
import TableBids from 'App/components/table/TableBids.js'
import MessageModal from 'App/components/modal/MessageModal.js'
import ChooseGame from 'App/components/modal/ChooseGame.js'
import DisplayGameCodes from 'App/components/modal/DisplayGameCodes.js'
import GameSummary from 'App/components/modal/GameSummary.js'
import BidCard from 'App/components/auction/BidCard.js'
import LivePlayBid from 'App/components/auction/LivePlayBid.js'
import PlayersJoined from 'App/components/playerName/PlayersJoined.js'
import BotReplace from 'App/components/modal/BotReplace.js'
import SendMessage from 'App/components/modal/SendMessage.js'
import Text from 'App/components/text/Text.js'
import RenderHTML from 'App/components/text/RenderHTML.js'
import SendMessageButton from 'App/components/message/SendMessageButton.js'
import SpeechBubble from 'App/components/message/SpeechBubble.js'
// import ClaimGameButton from 'App/components/message/ClaimGameButton.js'
import ClaimGame from 'App/components/modal/ClaimGame.js'
import UndoButton from 'App/components/message/UndoButton.js'
import UndoRequestApproval from 'App/components/modal/UndoRequestApproval.js'

@observer
class Game extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      markerVisible: {
        north: false,
        south: false,
        east: false,
        west: false
      },
      handVisible: {
        north: false,
        south: false,
        east: false,
        west: false
      },
      displayAsFan: {
        north: false,
        south: false,
        east: false,
        west: false
      },
      horizontalHand: {
        north: false,
        south: false,
        east: false,
        west: false
      },
      horizontalCards: {
        north: false,
        south: false,
        east: false,
        west: false
      },
      userToken: null,
      modalIsOpen: false,
      modalText: '',
      modalCloseWithOverlay: true,
      endGameModalIsOpen: false,
      gameType: null,
      numHumans: null,
      gameCodes: {
        northCode: null,
        eastCode: null,
        southCode: null,
        westCode: null
      },
      seat: {
        player: null,
        code: null
      },
      playerSession: {
        player: null,
        token: null
      }
    }
    this.cardClicked = this.cardClicked.bind(this) // called after rendered so need to bind it to this component
    this.showModal = this.showModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.menuClicked = this.menuClicked.bind(this)
    this.dealHands = this.dealHands.bind(this)
    this.bidSelected = this.bidSelected.bind(this)
    this.chooseStartSession = this.chooseStartSession.bind(this)
    this.joinSession = this.joinSession.bind(this)
    this.startSession = this.startSession.bind(this)
    this.seatSelected = this.seatSelected.bind(this)
    this.sendMessage = this.sendMessage.bind(this)
    this.displayGameCodes = this.displayGameCodes.bind(this)
    this.displaySendMessage = this.displaySendMessage.bind(this)
    this.displayBidHistory = this.displayBidHistory.bind(this)
    this.replayScenario = this.replayScenario.bind(this)
    this.claimGame = this.claimGame.bind(this)
    this.denyClaimGame = this.denyClaimGame.bind(this)
    this.acceptClaimGame = this.acceptClaimGame.bind(this)
    this.undoTurn = this.undoTurn.bind(this)
    this.approveUndo = this.approveUndo.bind(this)
    this.denyUndo = this.denyUndo.bind(this)
  }

  // Some useful 'global' lookup variables
  suitLookup = {
    S: 'spade',
    H: 'heart',
    D: 'diamond',
    C: 'club'
  }

  seatOrder = {
    S: 0,
    W: 1,
    N: 2,
    E: 3
  }

  nextPlayerLookup = {
    south: 'west',
    east: 'south',
    north: 'east',
    west: 'north'
  }

  componentDidMount () {
    this.login()

    this.storeUserToken()

    this.setState({
      modalText: <ChooseGame closeWithOverlay='true' startSession={this.startSession} joinSession={this.joinSession} />
    })
    this.showModal()
  }

  storeUserToken () {
    var token = getQueryVariable('token')
    GameStore.storeUserToken(token)
    console.log('this my token', token)

    // this needs to be passed to the server
  }

  async login () {
    try {
      await AuthStore.login('apiuser', 'RcP817f1nEsE8H68YO7g')
    } catch (err) {
      console.log(err)
    }
  }

  closeModal (err) {
    // Clear error in case we got here that way
    if (err) {
      GameStore.clearError()
    }

    this.setState({
      modalIsOpen: false
    })
  }

  clearTimeout () {
    // Clear player timeout
    GameStore.clearPlayerTimeout()

    // And close modal
    this.setState({
      modalIsOpen: false
    })
  }

  /**
   * Replay same game with same players
   */
  replayScenario () {
    GameStore.replayScenario()
  }

  /**
   * Turn the partner of this player into a bot
   */
  replacePartnerWithBot () {
    GameStore.replacePartnerWithBot()
  }

  /**
   * Let the partner have more time to play
   */
  continueWaitingForPartner () {
    GameStore.continueWaitingForPartner()
  }

  /**
   * Undo has been approved
   */
  approveUndo () {
    GameStore.approveUndo()
    this.closeModal()
  }

  /**
   * Undo has been denied
   */
  denyUndo () {
    GameStore.denyUndo()
    this.closeModal()
  }

  showModal () {
    this.setState({
      modalIsOpen: true
    })
  }

  /**
   * FUNCTION: chooseStartSession
   *
   * User has selected start a Game
   */

  chooseStartSession () {
    this.displaySelectGame()
  }

  /**
   * FUNCTION: startSession
   *
   * Start a new game session
   */

  async startSession (scenario, type = 'full', numHumans = 'four', randomType = 'pure') {
    try {
      var gameCodes = await GameStore.startSession(scenario, type, numHumans, randomType)
    } catch (err) {
      console.log(err)
    }
    this.setState({
      gameCodes: gameCodes,
      gameType: type,
      numHumans: numHumans
    })

    // setup the game store
    GameStore.phase = 'setup'
    GameStore.setScenario(scenario)

    this.displayGameCodes()
  }

  /**
   * FUNCTION: displaySelectGame
   *
   * Displays the select game screen
   */

  displaySelectGame () {
    // this.setState({
    //   modalText: <MenuScenario scenarioSelected={this.dealHands} closeWithOverlay='true' joinSession={this.joinSession} allPlayersJoined={GameStore.allPlayersJoined} startSession={this.startSession} gameCodes={this.state.gameCodes} seatSelected={this.seatSelected} seat={this.state.seat} playerSession={this.state.playerSession} />
    // })
  }

  /**
 * FUNCTION: displayGameCodes
 *
 * Displays the select game screen
 */

  displayGameCodes () {
    this.setState({
      modalText: <DisplayGameCodes closeWithOverlay='true' numHumans={this.state.numHumans} joinSession={this.joinSession} allPlayersJoined={GameStore.allPlayersJoined} gameCodes={this.state.gameCodes} />
    })
  }

  /**
   * FUNCTION: displayChooseGame
   *
   * Displays the choose game screen
   */

  displayChooseGame () {
    this.setState({
      modalText: <ChooseGame closeWithOverlay='true' startSession={this.startSession} joinSession={this.joinSession} />
    })
  }

  /**
   * FUNCTION: displaySendMessage
   *
   * Display the Send a message screen
   */

  displaySendMessage () {
    var messageDisplay = ''
    if (GameStore.messages) {
      messageDisplay = GameStore.messages.map((message, index) => (
        <Text key={index} sx={{ fontSize: '12px', fontWeight: '300' }}><RenderHTML HTML={message} /></Text>
      ))
    }
    this.setState({
      modalText:
  <>
    <Flex>
      <Box
        sx={{
          flexGrow: 1,
          height: '100px',
          marginBottom: '10px'
        }}
      >
        <Scrollbars>
          <Box>
            {messageDisplay}
          </Box>
        </Scrollbars>
      </Box>
    </Flex>
    <SendMessage closeWithOverlay='true' sendMessage={this.sendMessage} spectator={GameStore.systemOverride} />
  </>
    })

    this.showModal()

    GameStore.clearUnreadMessages()
  }

  /**
   * FUNCTION: sendMessage
   *
   * Send a message to other players
   */

  sendMessage (toPlayer, message) {
    GameStore.sendMessage(toPlayer, message)
    this.closeModal()
  }
  /**
   * FUNCTION: seatSelected
   *
   * User starting a game has selected which seat to sit at
   */

  seatSelected (player) {
    var seat = {
      player: player
    }

    var code

    switch (player) {
      case 'North':
        code = this.state.gameCodes.northCode
        break
      case 'East':
        code = this.state.gameCodes.eastCode
        break
      case 'South':
        code = this.state.gameCodes.southCode
        break
      case 'West':
        code = this.state.gameCodes.westCode
        break
    }

    seat.code = code

    this.setState({
      seat: seat
    })

    this.displaySelectGame()
  }

  /**
   * FUNCTION: joinSession
   *
   * Join a session using the codes provided from startSession
   */

  async joinSession (code, name) {
    // If starting a session code will be
    // stored in this state
    if (!code) {
      code = this.state.seat.code
    }

    try {
      var playerSession = await GameStore.joinSession(code, name)
    } catch (err) {
      console.log(err)
    }

    // if (!playerSession.error) {
    //   this.setState({
    //     playerSession: playerSession
    //   })
    // }

    if (playerSession && !playerSession.error) {
      this.closeModal()

      this.setHandVisibility()
    }

    return playerSession
  }

  async bidSelected (player, rank, suit, pass, double, redouble) {
    await GameStore.storeBid(player, rank, suit, pass, double, redouble)

    return GameStore.bidError
  }

  async cardClicked (hand, rank, suit) {
    // Card has been clicked & this has been passed up from Card
    // Therefore anything in here refers to the Card not Hand
    // so this will not work for Hand functions
    //
    // However, bind it in the constructor and it works

    // Don't allow if we are the dummy

    if (GameStore.isNotDummy()) {
      var playCardResult = await GameStore.playCard(hand, rank, suit)

      if (playCardResult && playCardResult.error) {
        this.setState({
          modalText: playCardResult.error
        })
        this.showModal()
      }
    }
  }

  resetSingleSuitHand (player) {
    // Reset single hand suit
    if (GameStore.phase === 'play') {
      GameStore.resetSingleSuitHand(player)
    }
  }

  dealHands (scenario) {
    // Deal hands for selected scenario
    this.closeModal()
    GameStore.dealHands(scenario)
    this.setMarker()
    this.setHandVisibility()
  }

  setMarker () {
    var markerVisible = {
      north: false,
      south: false,
      east: false,
      west: false
    }

    markerVisible[GameStore.turn] = true

    this.setState({ markerVisible: markerVisible })
  }

  setHandVisibility () {
    var handVisible = {
      north: GameStore.systemOverride.north,
      south: GameStore.systemOverride.south,
      east: GameStore.systemOverride.east,
      west: GameStore.systemOverride.west
    }

    handVisible.south = true

    // Is the card hand in fan shape?
    var displayAsFan = {
      north: false,
      south: true,
      east: false,
      west: false
    }

    // Is the hand vertical or horizontal?
    var horizontalHand = {
      north: true,
      south: true,
      east: false,
      west: false
    }

    // Are the cards vertical or horizontal?
    var horizontalCards = {
      north: false,
      south: false,
      east: true,
      west: true
    }

    this.setState({ handVisible: handVisible })
    this.setState({ displayAsFan: displayAsFan })
    this.setState({ horizontalHand: horizontalHand })
    this.setState({ horizontalCards: horizontalCards })
  }

  /**
   *
   * Concede game button has been pressed
   */

  concedeGame () {
    if (window.confirm('Are you sure you wish to concede the game?')) {
      GameStore.concedeGame()
    }
  }

  /**
   *
   * Concede game button has been pressed
   */

  claimGame () {
    GameStore.claimGame()
  }

  /**
   *
   * Undo turn button has been pressed
   */

  undoTurn () {
    GameStore.undoRequest()
    alert('An undo has been requested. This must be approved by both of your opponents.')
  }

  menuClicked (menu) {
    // NOTE: This modalText needs sanitizing somehow - use dangerouslySetInnerHTML ?

    switch (menu) {
      case 1:
        // Start or Join a game
        // Close End Game Summary in case called from there
        this.setState({
          endGameModalIsOpen: false
        })

        this.displayChooseGame()

        this.showModal()
        break
      case 2:
        // Bid History
        var bidHistoryDisplay
        var bidArray = {
          west: [],
          north: [],
          east: [],
          south: []
        }

        var bidOrderIndex = {
          west: 0,
          north: 1,
          east: 2,
          south: 3
        }

        var bidOrder = ['west', 'north', 'east', 'south']

        var bidHistory = GameStore.getBidHistory()

        if (bidHistory && bidHistory.length !== 0) {
          var opener = bidHistory[0].player

          // Work out order of bids made
          for (let i = 0; i < bidOrderIndex[opener]; i++) {
            bidOrder.push(bidOrder.shift())
          }

          for (let i = 0; i < bidHistory.length; i++) {
            if (bidHistory[i] === undefined) {
              break
            }
            var bidPlayer = bidHistory[i].player
            var bidValue = parseInt(bidHistory[i].value)
            var bidSuit = bidHistory[i].suit
            var pass = bidHistory[i].pass
            var double = bidHistory[i].double
            var redouble = bidHistory[i].redouble
            var bidMade = <td><BidCard bidValue={bidValue} bidSuit={bidSuit} pass={pass} double={double} redouble={redouble} alignSelf='center' /></td>
            bidArray[bidPlayer].push(bidMade)
          }

          var bidRows = []
          for (let i = 0; i < bidHistory.length; i += 4) {
            var bidDisplay = []
            for (let j = 0; j < 4; j++) {
              if (bidArray[bidOrder[j]][i / 4] === undefined) {
                break
              }
              bidDisplay.push(bidArray[bidOrder[j]][i / 4])
            }
            bidRows.push(<tr>{bidDisplay}</tr>)
          }

          bidHistoryDisplay = <Box sx={{ height: '300px' }}><Scrollbars><table className='bid-history'><thead><tr><th>{bidOrder[0].charAt(0).toUpperCase()}</th><th>{bidOrder[1].charAt(0).toUpperCase()}</th><th>{bidOrder[2].charAt(0).toUpperCase()}</th><th>{bidOrder[3].charAt(0).toUpperCase()}</th></tr></thead><tbody>{bidRows}</tbody></table></Scrollbars></Box>
        } else {
          bidHistoryDisplay = 'No bids made yet'
        }

        this.setState({
          modalCloseWithOverlay: true,
          modalText: bidHistoryDisplay,
          showTextModal: true
        })
        this.showModal()

        break
      case 3:
        // Trick History
        var trickHistoryDisplay
        var trickArray = {
          west: [],
          north: [],
          east: [],
          south: []
        }

        var trickOrderIndex = {
          west: 0,
          north: 1,
          east: 2,
          south: 3
        }

        var trickOrderInitial = ['west', 'north', 'east', 'south']
        var trickOrder = []

        var trickHistory = GameStore.getTrickHistory()

        if (trickHistory && trickHistory.length !== 0) {
          // const trickOpener = trickHistory[0].player

          // // Work out order of tricks played
          // for (let i = 0; i < trickOrderIndex[trickOpener]; i++) {
          //   trickOrder.push(trickOrderInitial.shift())
          // }

          for (let i = 0; i < trickHistory.length; i++) {
            if (trickHistory[i] === undefined) {
              break
            }
            var trickPlayer = trickHistory[i].player
            var trickValue

            if (trickHistory[i].rank === 'T' || trickHistory[i].rank === 'J' || trickHistory[i].rank === 'Q' || trickHistory[i].rank === 'K' || trickHistory[i].rank === 'A') {
              trickValue = trickHistory[i].rank
            } else {
              trickValue = parseInt(trickHistory[i].rank)
            }
            var trickSuit = trickHistory[i].suit

            var trickPlayed = <td key={i}><FontAwesomeIcon icon={['fas', trickSuit.substring(0, trickSuit.length - 1)]} className={`color-${trickSuit}`} />{trickValue}</td>
            trickArray[trickPlayer].push(trickPlayed)
          }

          var trickRows = []
          for (let i = 0; i < trickHistory.length; i += 4) {
            trickOrder = trickOrderInitial
            // Work out order of tricks played
            for (let i = 0; i < trickOrderIndex[trickHistory[i].player]; i++) {
              trickOrder.push(trickOrder.shift())
            }

            var trickDisplay = []
            for (let j = 0; j < 4; j++) {
              if (trickArray[trickOrder[j]][i / 4] === undefined) {
                break
              }
              trickDisplay.push(trickArray[trickOrder[j]][i / 4])
            }
            trickRows.push(<tr>{trickDisplay}</tr>)
          }

          trickHistoryDisplay = <Box sx={{ height: '300px' }}><Scrollbars><table className='trick-history'><thead><tr><th>{trickOrder[0].charAt(0).toUpperCase()}</th><th>{trickOrder[1].charAt(0).toUpperCase()}</th><th>{trickOrder[2].charAt(0).toUpperCase()}</th><th>{trickOrder[3].charAt(0).toUpperCase()}</th></tr></thead><tbody>{trickRows}</tbody></table></Scrollbars></Box>
        } else {
          trickHistoryDisplay = 'No bids made yet'
        }

        // if (Array.isArray(trickHistory)) {
        //   var roundArray = []
        //   var trickArray = []

        //   // Build Table of tricks
        //   roundArray = trickHistory.map(function (trick) {
        //     // Create dummy array so that if starting
        //     // player is not south the cards go in the right place
        //     trickArray = [
        //       <td key={trickArray.id} />,
        //       <td key={trickArray.id} />,
        //       <td key={trickArray.id} />,
        //       <td key={trickArray.id} />
        //     ]
        //     var round = trick.play
        //     for (var i = 0; i < round.length; i++) {
        //       trickArray[this.seatOrder[round[i].seat.symbol]] = <td key={round[i].id} className={`${this.suitLookup[round[i].card.suit]} ${i === 0 ? 'start-player' : null}`}>{round[i].card.rank}<FontAwesomeIcon icon={['fas', this.suitLookup[round[i].card.suit]]} /></td>
        //     }
        //     return <tr key={trick.id}>{trickArray}</tr>
        //   }, this) // Need this here so it references correct this inside map function
        //   trickHistoryDisplay = <Scrollbars><table className='trick-history'><thead><tr><th>S</th><th>W</th><th>N</th><th>E</th></tr></thead><tbody>{roundArray}</tbody></table></Scrollbars>
        // } else {
        //   trickHistoryDisplay = trickHistory
        // }

        this.setState({
          modalCloseWithOverlay: true,
          modalText: trickHistoryDisplay,
          showTextModal: true
        })
        this.showModal()
        break
      case 4:
        // Concede Game
        this.concedeGame()
        break
      case 5:
        // Claim Game
        this.claimGame()
        break
      case 6:
        // Exit game
        alert('COMING SOON')
        break
    }
  }

  displayBidHistory () {
    this.menuClicked(2)
    GameStore.clearDisplayBidHistory()
  }

  displayClaimGame () {
    this.setState({
      modalCloseWithOverlay: false,
      modalText: <ClaimGame acceptClaimGame={this.acceptClaimGame} denyClaimGame={this.denyClaimGame} />,
      showTextModal: true
    })
    this.showModal()
  }

  acceptClaimGame () {
    GameStore.approveClaim()
    this.setState({
      claimGameDisplayed: false
    })
    this.closeModal()
  }

  denyClaimGame () {
    GameStore.denyClaim()
    this.setState({
      claimGameDisplayed: false
    })
    this.closeModal()
  }

  render () {
    // Doesn't seem quite right but works
    if (!this.state.endGameModalIsOpen && GameStore.gameOver) {
      this.setState({
        endGameModalIsOpen: true
      })
    }

    if (GameStore.displayBidHistory & !this.state.displayBidHistory) {
      this.setState({
        displayBidHistory: true
      })
      this.displayBidHistory()
    }

    if (GameStore.claimGameRequested & !this.state.claimGameDisplayed) {
      this.setState({
        claimGameDisplayed: true
      })
      this.displayClaimGame()
    }

    return (
      <>
        <NavBar menuClicked={this.menuClicked} />
        <Flex
          sx={{
            position: 'absolute',
            top: [110],
            flexDirection: 'row',
            width: ['100%', 600, 700],
            justifyContent: GameStore.unreadMessages > 0 ? 'space-between' : 'flex-end'
          }}
        >
          {GameStore.unreadMessages > 0 &&
            <Box
              className='speech-bubble'
              sx={{
                width: '80%'
              }}
            >
              <SpeechBubble><RenderHTML HTML={GameStore.messages[0]} /></SpeechBubble>
            </Box>}
          {GameStore.phase && GameStore.phase !== 'setup' &&
            <Box
              sx={{
                display: 'inline-flex'
              }}
            >
              <SendMessageButton
                displaySendMessage={this.displaySendMessage}
                unreadMessages={GameStore.unreadMessages}
              />
              {/* <ClaimGameButton
                claimGame={this.claimGame}
              /> */}
              {/* Only display the Undo button if
                    1. if next player is waiting to play and is not dummy
              2. if next player is player after dummy */}
              {(((GameStore.turn === this.nextPlayerLookup[GameStore.playerSession.player]) && (GameStore.turn === GameStore.sessionStatus.dummy)) || ((GameStore.turn === this.nextPlayerLookup[GameStore.sessionStatus.dummy]) && (GameStore.playerSession.player === GameStore.sessionStatus.declarer))) &&
                <UndoButton
                  undoTurn={this.undoTurn}
                />}
            </Box>}
        </Flex>
        <Flex
          sx={{
            p: 2,
            color: 'white',
            flexShrink: 0,
            justifyContent: 'center',
            alignItems: 'center',
            width: [375, 700, 700],
            height: [567, 650, 650],
            margin: '0px auto',
            overflow: 'hidden',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)'
          }}
        >
          {/* Main table view */}
          <Box
            sx={{
              backgroundImage: 'url(shutterstock_347563919.jpg)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              borderRadius: 16,
              borderTop: 4,
              borderRight: 4,
              borderBottom: 4,
              borderLeft: 4,
              borderColor: '#492a2c',
              // borderStyle: 'solid',
              width: [300, 300],
              height: [300, 300],
              position: 'relative',
              display: 'flex'
            }}
          >
            <MessageModal showModal={this.state.modalIsOpen} closeModal={this.closeModal} closeWithOverlay={this.state.modalCloseWithOverlay}>
              {this.state.modalText}
            </MessageModal>

            {/* Outer view 1 */}
            {/* This holds the Player names and the inner view */}
            <Box
              sx={{
                width: [240, 340],
                height: [240, 340],
                borderWidth: 2,
                borderColor: 'red',
                // borderStyle: 'solid',
                position: 'absolute',
                left: '50%',
                ml: [-120, -170],
                top: '50%',
                mt: [-120, -170]
              }}
            >
              {/* Player Names */}
              {/* North position */}
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  marginLeft: [-45, -60],
                  display: GameStore.phase === 'auction' && (GameStore.bidTurn === GameStore.playerName[0]) ? 'none' : 'block',
                  transform: ['translateY(-250px)', 'translateY(-315px)']
                }}
              >
                <PlayerName active={GameStore.turn || GameStore.bidTurn}>{GameStore.playerRealName[2] ? GameStore.playerRealName[2] : GameStore.playerName[2]}</PlayerName>
              </Box>

              {/* East Position */}
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: GameStore.dummy === 'east' ? [-115, -45] : [-35, -45],
                  // right: [20, 26], /* 26 is the height of the PlayerName container (20 for mobile) */
                  mt: [-13, -13],
                  transform: ['translateX(250px) rotate(-90deg)', 'translateX(315px) rotate(-90deg)'],
                  display: GameStore.phase === 'auction' && (GameStore.bidTurn === GameStore.playerName[0]) ? 'none' : 'block'
                }}
              >
                <PlayerName active={GameStore.turn || GameStore.bidTurn}>{GameStore.playerRealName[3] ? GameStore.playerRealName[3] : GameStore.playerName[3]}</PlayerName>
              </Box>

              {/* South position */}
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: '50%',
                  marginLeft: [-45, -60],
                  display: GameStore.phase === 'auction' && (GameStore.bidTurn === GameStore.playerName[0]) ? 'none' : 'block',
                  transform: ['translateY(250px)', 'translateY(315px)']
                }}
              >
                <PlayerName active={GameStore.turn || GameStore.bidTurn}>{GameStore.playerRealName[0] ? GameStore.playerRealName[0] : GameStore.playerName[0]}</PlayerName>
              </Box>

              {/* West position */}
              <Box
                sx={{
                  position: 'absolute',
                  left: GameStore.dummy === 'west' ? [130, 100] : [65, 100],
                  top: '50%',
                  mt: [-13, -13],
                  transform: ['translateX(-127px) rotate(90deg)', 'translateX(-150px) rotate(90deg)'],
                  display: GameStore.phase === 'auction' && (GameStore.bidTurn === GameStore.playerName[0]) ? 'none' : 'block'
                }}
              >
                <PlayerName active={GameStore.turn || GameStore.bidTurn}>{GameStore.playerRealName[1] ? GameStore.playerRealName[1] : GameStore.playerName[1]}</PlayerName>
              </Box>

              <Flex
                sx={{
                  justifyContent: 'center',
                  flexDirection: 'column',
                  borderWidth: 2,
                  borderColor: 'blue',
                  // borderStyle: 'solid',
                  height: [125, 150],
                  width: [240, 380],
                  left: ['50%'],
                  marginLeft: [-120, -190],
                  position: 'absolute',
                  top: [-130, -120]
                }}
              >
                {GameStore.seats && GameStore.singleSuitHands[GameStore.seats[2]].length > 0
                  ? (
                    <Hand single showCloseMarker clickCloseMarker={() => this.resetSingleSuitHand('north')} spacing='1.2' player='north' dummy={GameStore.dummy === 'north'} active={(GameStore.turn === GameStore.sessionStatus.dummy) && (GameStore.playerSession.player === GameStore.sessionStatus.declarer)} hand={GameStore.singleSuitHands[GameStore.seats[2]]} visible={this.state.handVisible.north} showMarker={this.state.markerVisible.north} displayAsFan={this.state.displayAsFan.north} horizontalHand={this.state.horizontalHand.north} horizontalCards={this.state.horizontalCards.north} cardClicked={this.cardClicked} />
                  ) : (((GameStore.hands.north !== '-') || GameStore.systemOverride.north) &&
                    // <Hand observer={GameStore.systemOverride} showCloseMarker={false} spacing='0.32' player='north' dummy={GameStore.dummy === 'north'} active={(GameStore.turn === GameStore.sessionStatus.dummy) && (GameStore.playerSession.player === GameStore.sessionStatus.declarer)} hand={GameStore.hands.north} visible={this.state.handVisible.north} showMarker={this.state.markerVisible.north} displayAsFan={this.state.displayAsFan.north} horizontalHand={this.state.horizontalHand.north && GameStore.dummy !== 'north'} horizontalCards={this.state.horizontalCards.north} cardClicked={this.cardClicked} />
                    <Hand
                      observer={GameStore.systemOverride.north}
                      showCloseMarker={false}
                      spacing='0.32'
                      player='north'
                      sessionStatus={GameStore.sessionStatus}
                      dummy={GameStore.dummy === 'north'}
                      active={(GameStore.turn === GameStore.sessionStatus.dummy) && (GameStore.playerSession.player === GameStore.sessionStatus.declarer)}
                      hand={GameStore.hands.north}
                      visible={this.state.handVisible.north}
                      showMarker={this.state.markerVisible.north}
                      displayAsFan={this.state.displayAsFan.north}
                      horizontalHand={this.state.horizontalHand.north && GameStore.dummy !== 'north'}
                      horizontalCards={this.state.horizontalCards.north}
                      cardClicked={this.cardClicked}
                    />
                  )}

                {/* <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: '50%',
                    marginLeft: [-45, -60],
                    display: GameStore.phase === 'auction' && (GameStore.bidTurn === GameStore.playerName[0]) ? 'none' : 'block'
                  }}
                >
                  <PlayerName active={GameStore.turn || GameStore.bidTurn}>{GameStore.playerRealName[2] ? GameStore.playerRealName[2] : GameStore.playerName[2]}</PlayerName>
                </Box> */}
              </Flex>
              <Flex
                sx={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 2,
                  borderColor: 'red',
                  // borderStyle: 'solid',
                  width: [120, 200],
                  height: '100%',
                  position: 'absolute',
                  left: GameStore.dummy === 'east' ? [190, 320] : [250, 320]
                }}
              >
                {/* <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: [-35, -45],
                    mt: [-13, -13],
                    transform: 'rotate(-90deg)',
                    display: GameStore.phase === 'auction' && (GameStore.bidTurn === GameStore.playerName[0]) ? 'none' : 'block'
                  }}
                >
                  <PlayerName active={GameStore.turn || GameStore.bidTurn}>{GameStore.playerRealName[3] ? GameStore.playerRealName[3] : GameStore.playerName[3]}</PlayerName>
                </Box> */}
                {((GameStore.hands.east !== '-') || GameStore.systemOverride.east) &&
                  <Hand
                    observer={GameStore.systemOverride.east}
                    spacing='0.3'
                    player='east'
                    dummy={GameStore.dummy === 'east'}
                    sessionStatus={GameStore.sessionStatus}
                    active={GameStore.turn}
                    hand={GameStore.hands.east}
                    visible={this.state.handVisible.east}
                    showMarker={this.state.markerVisible.east}
                    displayAsFan={this.state.displayAsFan.east}
                    horizontalHand={this.state.horizontalHand.east || GameStore.dummy === 'east'}
                    horizontalCards={this.state.horizontalCards.east && GameStore.dummy !== 'east'}
                    cardClicked={this.cardClicked}
                  />}
              </Flex>
              <Flex
                sx={{
                  justifyContent: 'center',
                  flexDirection: GameStore.dummy === 'south' ? 'row' : 'column',
                  borderWidth: 2,
                  borderColor: 'green',
                  // borderStyle: 'solid',
                  height: [120, 180],
                  width: [300, 500],
                  left: ['50%'],
                  marginLeft: [-150, -250],
                  position: 'absolute',
                  top: [245, 300]
                }}
              >
                {/* <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    marginLeft: [-45, -60],
                    display: GameStore.phase === 'auction' && (GameStore.bidTurn === GameStore.playerName[0]) ? 'none' : 'block'
                  }}
                >
                  <PlayerName active={GameStore.turn || GameStore.bidTurn}>{GameStore.playerRealName[0] ? GameStore.playerRealName[0] : GameStore.playerName[0]}</PlayerName>
                </Box> */}
                {GameStore.seats && GameStore.singleSuitHands[GameStore.seats[0]].length > 0
                  ? (
                    <Hand sessionStatus={GameStore.sessionStatus} showCloseMarker clickCloseMarker={() => this.resetSingleSuitHand('south')} spacing='1.2' player='south' dummy={GameStore.dummy === 'south'} active={GameStore.turn} hand={GameStore.singleSuitHands[GameStore.seats[0]]} visible={this.state.handVisible.south} showMarker={this.state.markerVisible.south} displayAsFan={this.state.displayAsFan.south} horizontalHand={this.state.horizontalHand.south} horizontalCards={this.state.horizontalCards.south} cardClicked={this.cardClicked} />
                  ) : (GameStore.hands.south !== '-' &&
                    <Hand sessionStatus={GameStore.sessionStatus} single observer={GameStore.systemOverride.south} showCloseMarker={false} spacing='0.32' player='south' dummy={GameStore.dummy === 'south'} active={(GameStore.seats && (GameStore.seats[0] === GameStore.turn)) && (GameStore.turn !== GameStore.sessionStatus.dummy)} hand={GameStore.hands.south} visible={GameStore.phase === 'setup' ? false : this.state.handVisible.south} showMarker={this.state.markerVisible.south} displayAsFan={this.state.displayAsFan.south} horizontalHand={this.state.horizontalHand.south && GameStore.dummy !== 'south'} horizontalCards={this.state.horizontalCards.south} cardClicked={this.cardClicked} />
                  )}
              </Flex>
              <Flex
                sx={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 2,
                  borderColor: 'yellow',
                  // borderStyle: 'solid',
                  width: [120, 200],
                  height: '100%',
                  position: 'absolute',
                  left: GameStore.dummy === 'west' ? [-80, -100] : [-90, -100]
                }}
              >
                {((GameStore.hands.west !== '-') || GameStore.systemOverride.west) &&
                  <Hand
                    observer={GameStore.systemOverride.west}
                    sessionStatus={GameStore.sessionStatus}
                    spacing='0.3'
                    player='west'
                    dummy={GameStore.dummy === 'west'}
                    active={GameStore.turn}
                    hand={GameStore.hands.west}
                    visible={this.state.handVisible.west}
                    showMarker={this.state.markerVisible.west}
                    displayAsFan={this.state.displayAsFan.west}
                    horizontalHand={this.state.horizontalHand.west || GameStore.dummy === 'west'}
                    horizontalCards={this.state.horizontalCards.west && GameStore.dummy !== 'west'}
                    cardClicked={this.cardClicked}
                  />}
                {/* <Box
                  sx={{
                    position: 'absolute',
                    left: [65, 100],
                    top: '50%',
                    mt: [-13, -13],
                    transform: 'rotate(90deg)',
                    display: GameStore.phase === 'auction' && (GameStore.bidTurn === GameStore.playerName[0]) ? 'none' : 'block'
                  }}
                >
                  <PlayerName active={GameStore.turn || GameStore.bidTurn}>{GameStore.playerRealName[1] ? GameStore.playerRealName[1] : GameStore.playerName[1]}</PlayerName>
                </Box> */}
              </Flex>
            </Box>

            {/* Inner view */}
            {/* Waiting for all players to join */}
            {(GameStore.phase === 'setup' && !GameStore.allPlayersJoined && GameStore.allPlayers) &&
              <PlayersJoined north={GameStore.allPlayers.north ? GameStore.allPlayers.north : ''} east={GameStore.allPlayers.east ? GameStore.allPlayers.east : ''} south={GameStore.allPlayers.south ? GameStore.allPlayers.south : ''} west={GameStore.allPlayers.west ? GameStore.allPlayers.west : ''} />}

            {/* If the bid turn matches the player name then they must be in 'south's position */}
            {GameStore.bidTurn === GameStore.playerSession.player && GameStore.phase === 'auction' &&
              <LivePlayBid player={GameStore.playerSession.player} bidSelected={this.bidSelected} />}
            {/* This will display the Bid Cards or played cards */}
            <Box
              sx={{
                borderWidth: 2,
                borderColor: 'pink',
                // borderStyle: 'solid',
                position: 'absolute',
                left: '50%',
                top: '50%',
                width: GameStore.phase === 'auction' && (GameStore.bidTurn === GameStore.playerName[0]) ? [300, 500] : [180, 280],
                height: GameStore.phase === 'auction' && (GameStore.bidTurn === GameStore.playerName[0]) ? [300, 500] : [180, 280],
                ml: GameStore.phase === 'auction' && (GameStore.bidTurn === GameStore.playerName[0]) ? [-150, -250] : [-90, -143],
                mt: GameStore.phase === 'auction' && (GameStore.bidTurn === GameStore.playerName[0]) ? [-150, -250] : [-90, -140]
              }}
            >
              {GameStore.gameOver ? <GameSummary primaryPlayer={GameStore.primaryPlayer} replayScenario={this.replayScenario} showModal={this.state.endGameModalIsOpen} closeModal={this.closeModal} closeWithOverlay={this.state.modalCloseWithOverlay} menuClicked={this.menuClicked} dealHands={this.dealHands} /> : ''}
              {/* <GameSummary primaryPlayer={GameStore.primaryPlayer} replayScenario={this.replayScenario} showModal closeModal={this.closeModal} closeWithOverlay={this.state.modalCloseWithOverlay} menuClicked={this.menuClicked} dealHands={this.dealHands} /> */}

              {GameStore.phase === 'play' || GameStore.phase === 'results' ? <TableCards northSuit={GameStore.table.north.suit} northRank={GameStore.table.north.rank} southSuit={GameStore.table.south.suit} southRank={GameStore.table.south.rank} eastSuit={GameStore.table.east.suit} eastRank={GameStore.table.east.rank} westSuit={GameStore.table.west.suit} westRank={GameStore.table.west.rank} /> : ''}
              {GameStore.phase === 'auction' ? <TableBids bidSelected={this.bidSelected} /> : ''}
            </Box>
          </Box>
        </Flex>
        {GameStore.error &&
          <MessageModal showModal={GameStore.error} closeModal={() => this.closeModal(true)} closeWithOverlay={this.state.modalCloseWithOverlay}>
            {GameStore.errorMessage}
          </MessageModal>}
        {GameStore.playerTimedOut &&
          <MessageModal showModal={GameStore.playerTimedOut} closeModal={() => this.clearTimeout()} closeWithOverlay={this.state.modalCloseWithOverlay}>
            It is your turn. Please take your turn or you may be removed from the game.
          </MessageModal>}
        {GameStore.partnerTimedOut &&
          <MessageModal showModal={GameStore.partnerTimedOut} closeModal={() => this.continueWaitingForPartner()} closeWithOverlay={this.state.modalCloseWithOverlay}>
            <BotReplace replacePartnerWithBot={() => this.replacePartnerWithBot()} continueWaitingForPartner={() => this.continueWaitingForPartner()} />
          </MessageModal>}
        {GameStore.approveUndoRequest &&
          <MessageModal showModal={GameStore.approveUndoRequest} closeModal={() => this.denyUndo()} closeWithOverlay={this.state.modalCloseWithOverlay}>
            <UndoRequestApproval approveUndo={this.approveUndo} denyUndo={this.denyUndo} undoRequestedBy={GameStore.undoRequestedBy} />
          </MessageModal>}
      </>
    )
  }
}
export default Game
