/* eslint no-debugger: "warn" */

// dependencies
import React from 'react';

// stores
import AuthStore from 'App/stores/AuthStore.js';
import { observer } from 'mobx-react';

// const serverRoot = 'http://localhost:1337' // ?username=dom@appt.digital&password=password
// const serverRoot = 'https://dev-portal-atbridge.ontheappt.cloud' // ?username=dom@appt.digital&password=password
const serverRoot = 'https://stage-atb-portal.ontheappt.cloud'; // ?username=dom@appt.digital&password=password
// const serverRoot = 'https://prod-portal-atbridge.ontheappt.cloud' // ?username=dom@appt.digital&password=password

@observer
class Api extends React.Component {
  login = async (username, password) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    var options = {
      method: 'POST',
      headers,
    };

    const requestUrl = '/api/auth/login';
    const urlParams = `?username=${encodeURIComponent(
      username,
    )}&password=${encodeURIComponent(password)}`;

    const fullUrl = `${serverRoot}${requestUrl}${urlParams}`;

    const request = new Request(fullUrl, options);
    const response = await fetch(request);
    return response.json();
  };

  get = async (url, params = {}) => {
    // check for an api key, otherwise throw an error

    const apiToken = AuthStore.apiToken;

    if (!apiToken) {
      throw Error('Missing API Token');
    }

    const options = {
      method: 'GET',
    };

    params.apiToken = apiToken;

    let paramsStr = '';
    if (params) {
      paramsStr = '';
      for (let key in params) {
        let value = params[key];
        console.log('key', key);
        console.log('value', value);
        if (typeof value === 'object') {
          value = JSON.stringify(value);
        }
        key = encodeURIComponent(key);
        value = encodeURIComponent(value);

        if (paramsStr === '') {
          paramsStr += '?';
        } else {
          paramsStr += '&';
        }
        paramsStr += `${key}=${value}`;
      }
    }

    const request = new Request(`${serverRoot}${url}${paramsStr}`, options);
    const response = await fetch(request);
    return response.json();
  };

  post = async (url, params = {}, options = {}) => {
    // check for an api key, otherwise throw an error

    const apiToken = AuthStore.apiToken;

    if (!apiToken) {
      throw Error('Missing API Token');
    }

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    var optionsParams = {
      method: 'POST',
      headers,
      body: JSON.stringify(options),
    };

    params.apiToken = apiToken;

    let paramsStr = '';
    if (params) {
      paramsStr = '';
      for (let key in params) {
        let value = params[key];
        console.log('key', key);
        console.log('value', value);
        if (typeof value === 'object') {
          value = JSON.stringify(value);
        }
        key = encodeURIComponent(key);
        value = encodeURIComponent(value);

        if (paramsStr === '') {
          paramsStr += '?';
        } else {
          paramsStr += '&';
        }
        paramsStr += `${key}=${value}`;
      }
    }

    const request = new Request(
      `${serverRoot}${url}${paramsStr}`,
      optionsParams,
    );
    const response = await fetch(request);
    const returnResponse = await response.json();
    console.log('response ', returnResponse);
    return returnResponse;
  };

  // post = async (url, params = {}) => {
  //   const apiToken = AuthStore.apiToken

  //   if (!apiToken) {
  //     throw Error('Missing API Token')
  //   }

  //   params.apiToken = apiToken

  //   const headers = new Headers()
  //   headers.append('Content-Type', 'application/json')
  //   var options = {
  //     method: 'POST',
  //     headers,
  //     body: JSON.stringify(params)
  //   }
  //   const request = new Request(`${serverRoot}${url}`, options)
  //   const response = await fetch(request)
  //   return response
  // }

  // post = async (model) => {
  //     const headers = new Headers();
  //     headers.append("Content-Type", "application/json");
  //     var options = {
  //         method: "POST",
  //         headers,
  //         body: JSON.stringify(model)
  //     }
  //     const request = new Request(serverRoot, options);
  //     const response = await fetch(request);
  //     return response;
  // }
}

// export default Api
export default new Api();
