//
// Component: TableCards
//
// Displays the players cards played on the table
//

// dependencies
import React from 'react'
import { Flex, Box } from 'rebass'
import { observer } from 'mobx-react'

// components
import Card from 'App/components/card/Card.js'

// @observer
const TableCards = observer(class TableCards extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {

  }

  render () {
    return (
      <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          height: [180, 260],
          width: [180, 260]
        }}
      >
        <Box>
          <Card suit={this.props.northSuit} rank={this.props.northRank} visible='true' table='true' hand='north' />
          <Card suit={this.props.westSuit} rank={this.props.westRank} visible='true' table='true' hand='west' />
          <Card suit={this.props.eastSuit} rank={this.props.eastRank} visible='true' table='true' hand='east' />
          <Card suit={this.props.southSuit} rank={this.props.southRank} visible='true' table='true' hand='south' />
        </Box>
      </Flex>
    )
  }
})
export default TableCards
