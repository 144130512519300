//
// Component: PlayerName
//
// Displays the player name ie. north, south, east, west
//

// dependencies
import React from 'react'
import { Flex } from 'rebass'
import ReactFitText from 'react-fittext'

// components
import Label from 'App/components/text/Label.js'

class PlayerName extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    var opacity = 0.4
    var fontWeight = 400

    if (this.props.active) {
      var activePlayerKey = this.props.active.charAt(0).toUpperCase()
      var currentPlayerKey = this.props.children.charAt(0).toUpperCase()

      if (activePlayerKey === currentPlayerKey) {
        var borderWidth = '2px'
        var borderStyle = 'solid'
        var borderColor = '#fff'
        fontWeight = 700
        opacity = 1
      }
    }
    return (
      <Flex
        {...this.props}
        className={' label-relative-' + currentPlayerKey}
        sx={{
          bg: '#9d2723',
          justifyContent: 'center',
          py: 1,
          width: [90, 120],
          height: [20, 26],
          margin: '0 auto',
          textTransform: 'uppercase',
          borderWidth: borderWidth,
          borderStyle: borderStyle,
          borderColor: borderColor,
          opacity: opacity,
          fontWeight: fontWeight
        }}
      >
        <ReactFitText minFontSize={10}>
          <Label
            sx={{
              alignSelf: 'center'
            }}
          >
            {this.props.children}
          </Label>
        </ReactFitText>
      </Flex>
    )
  }
}
export default PlayerName
