// dependencies
import React from 'react'

import {
  Select as RebassSelect
} from '@rebass/forms'

// components
// import Translate from "App/components/text/Translate.js"

// TODO: translate the placeholder and value props

class Select extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    return (
      <RebassSelect {...this.props} />
    )
  }
}
export default Select
