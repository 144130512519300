//
// Component: Waiting
//
// Displays a waiting animation
//

// dependencies
import React from 'react'
import { Image, Flex } from 'rebass'
import { observer } from 'mobx-react'

@observer
class Waiting extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      cardIndex: 1,
      cards: {
        card1: false,
        card2: false,
        card3: false,
        card4: false
      }
    }
  }

  componentDidMount () {
    this.cardTimer = setInterval(function () {
      this.buildAnimation()
    }.bind(this),
    1000)
  }

  componentWillUnmount () {
    clearInterval(this.cardTimer)
  }

  buildAnimation () {
    let cardIndex = this.state.cardIndex
    let items = this.state.cards

    const item = !items['card' + cardIndex]

    items = {
      card1: false,
      card2: false,
      card3: false,
      card4: false
    }

    items['card' + cardIndex] = item

    if (cardIndex > 4) {
      cardIndex = 0
    } else {
      cardIndex++
    }

    this.setState({
      cards: items
    })

    this.setState({
      cardIndex: cardIndex
    })
  }

  render () {
    return (
      <Flex mt={3} className='player-hand-container'>
        {this.state.cards.card1 &&
          <Image
            sx={{
              width: [70],
              height: [98]
            }}
            draggable={false} src={require('../card/images/AH.svg')}
          />}
        {this.state.cards.card2 &&
          <Image
            sx={{
              width: [70],
              height: [98]
            }}
            draggable={false} src={require('../card/images/AS.svg')}
          />}
        {this.state.cards.card3 &&
          <Image
            sx={{
              width: [70],
              height: [98]
            }}
            draggable={false} src={require('../card/images/AD.svg')}
          />}
        {this.state.cards.card4 &&
          <Image
            sx={{
              width: [70],
              height: [98]
            }}
            draggable={false} src={require('../card/images/AC.svg')}
          />}
      </Flex>
    )
  }
}
export default Waiting
