import React from 'react'
// import PropTypes from 'prop-types'

import { Button, Flex, Box } from 'rebass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * Component: UndoButton
 *
 * Displays and controls the Undo button
 */
class UndoButton extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
    }

    this.undoTurn = this.undoTurn.bind(this)
  }

  undoTurn () {
    this.props.undoTurn()
  }

  render () {
    return (
      <Flex>
        <Box
          sx={{
            // position: 'absolute',
            // top: '110px',
            // right: '10px',
            zIndex: '10'
          }}
          // eslint-disable-next-line react/jsx-handler-names
          onClick={this.undoTurn}
        >
          <Button
            sx={{
              backgroundColor: 'transparent',
              fontSize: [3, 5],
              outline: 'transparent', // overrides an outline on :focus from rebass
              cursor: 'pointer',
              color: '#8cdff9',
              borderStyle: 'solid',
              borderColor: '#000',
              borderRadius: '50%',
              borderWidth: 1,
              width: '45px',
              height: '45px',
              padding: '0'
            }}
          >
            <FontAwesomeIcon icon={['fa', 'undo']} />
          </Button>
        </Box>
      </Flex>
    )
  }
}

export default UndoButton
