// dependencies
import React from 'react'
import { Flex } from 'rebass'
import { observer } from 'mobx-react'

// components
import Text from 'App/components/text/Text.js'
import Waiting from 'App/components/waiting/Waiting.js'

/**
 *
 * Displays names of players joined
 *
 */
@observer
class PlayersJoined extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount () {

  }

  render () {
    return (
      <>
        <Flex
          sx={{
            flexDirection: 'column',
            position: 'absolute',
            top: [45, 45],
            left: '50%',
            marginLeft: [-150, -150],
            marginTop: [-45, -45]
            // justifyContent: 'center',
            // alignItems: 'center',
            // margin: '0 auto'
          }}
        >
          <Flex
            sx={{
              height: [300, 300],
              width: [300, 300],
              backgroundColor: '#fff',
              borderRadius: '16px',
              borderTop: '4px',
              borderRight: '4px',
              borderBottom: '4px',
              borderLeft: '4px',
              borderColor: '#492a2c',
              borderStyle: 'solid',
              zIndex: 20,
              flexDirection: 'column',
              alignItems: 'center',
              color: '#000'
            }}
          >
            <Text>Waiting for all players to join</Text>
            <Text>Players joined so far:-</Text>
            {this.props.north &&
              <Text>North - {this.props.north}</Text>}
            {this.props.east &&
              <Text>East - {this.props.east}</Text>}
            {this.props.south &&
              <Text>South - {this.props.south}</Text>}
            {this.props.west &&
              <Text>West - {this.props.west}</Text>}

            <Waiting />
          </Flex>
        </Flex>
      </>
    )
  }
}
export default PlayersJoined
