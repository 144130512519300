//
// Component: MessageModal
//
// Displays a full screen modal
//
// To Use:
//
// Add these lines to the constructor of the component calling the modal
//
// this.showModal = this.showModal.bind(this)
// this.closeModal = this.closeModal.bind(this)
//
// Add a state
//
// modalIsOpen: false
//
// And add these functions
//
// showModal () {
// this.setState({
//   modalIsOpen: true
// })
// }

// closeModal () {
// this.setState({
//   modalIsOpen: false
// })
// }
//
// And call as follows:
//
// <MessageModal showModal={this.state.modalIsOpen} closeModal={this.closeModal}>
//   <Text>Message goes here</Text>
// </MessageModal>
//
//  Add bottom='true' which will fix the modal to the bottom of the screen
//
// NOTE: Also pass closeWithOverlay to allow closing modal
//       by clicking on overlay otherwise will need a button to close

import React from 'react'
import Modal from 'react-modal'
import { observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from 'App/components/form/Button.js'

@observer
class MessageModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
    }
  }

  render () {
    return (
      <Modal
        isOpen={this.props.showModal}
        ariaHideApp={false} // See http://reactcommunity.org/react-modal/accessibility/
        // eslint-disable-next-line react/jsx-handler-names
        // eslint-disable-next-line react/jsx-props-no-multi-spaces
        onRequestClose={this.props.closeWithOverlay ? this.props.closeModal : undefined}
        closeTimeoutMS={1000}
        className={(this.props.bottom ? 'modal bottom' : 'modal')}
        overlayClassName='modalOverlay'
      >
        {/* If close with overlay set then allowed to close by clicking outside of modal. Include a button also to make it clearer. */}
        {this.props.closeWithOverlay &&
          <Button
            sx={{
              backgroundColor: 'transparent',
              position: 'absolute',
              top: 0,
              right: 0,
              cursor: 'pointer'
            }}
            // eslint-disable-next-line react/jsx-handler-names
            onClick={this.props.closeModal}

          >
            <FontAwesomeIcon color='#000' icon={['fas', 'times']} />
          </Button>}
        {this.props.children}
      </Modal>
    )
  }
}
export default MessageModal
