import React from 'react'
// import PropTypes from 'prop-types'

import { Text, Flex, Box } from 'rebass'

/**
 * Component: SpeechBubble
 *
 * Displays a Speech Bubble
 */
class SpeechBubble extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
    }
  }

  render () {
    return (
      <Flex
        sx={{
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: '#000',
          padding: '10px',
          boxShadow: '5px 5px #e3e3e3'
        }}
      >
        <Box>
          <Text>
            {this.props.children}
          </Text>
        </Box>
      </Flex>
    )
  }
}

// SpeechBubble.propTypes = {
//   /** Shows if the menu is displayed */
//   menuDisplayed: PropTypes.bool
// }
export default SpeechBubble
