// dependencies
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Flex } from 'rebass'
import { library } from '@fortawesome/fontawesome-svg-core'

import { faSpade, faHeart, faClub, faDiamond, faQuestionSquare, faBars, faTimes, faTimesCircle, faEnvelope, faTrophy, faUndo, faComments } from '@fortawesome/pro-solid-svg-icons'

// assets
import 'App/assets/css/App.css'
import 'App/assets/css/animate.css'

// screens
import Game from 'App/screens/Game.js'

class App extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  // export default function App () {
  // setup react
  render () {
    library.add(faSpade, faHeart, faClub, faDiamond, faQuestionSquare, faBars, faTimes, faTimesCircle, faEnvelope, faTrophy, faUndo, faComments)
    return (
      <Router>
        <Flex
          sx={{
            padding: [0, 0],
            maxWidth: 800,
            margin: '0 auto',
            width: [375, 600, 700],
            marginTop: [100]
          }}
        >
          <Game />
        </Flex>
      </Router>
    )
  }
}
export default App
