//
// Component: Card
//
// Displays and controls a player's cards
//

// dependencies
import React from 'react'
import { Image } from 'rebass'

// Stylesheet
import './cards.css'

// components
import P from 'App/components/text/P.js'

// stores
// import GameStore from 'App/stores/GameStore'

class Card extends React.Component {
  //
  // props
  //
  // hand - north, south, east, west
  // horizontalCard - true/false - orientation of card
  // horizontalHand - true/false - orientation of hand the card belongs to
  // id - card identifier eg. north1, north2 etc
  // rank - A, 2, 3 ... J, Q, K
  // suit - S, H, D, C
  // visible - true/false - if false the back of the card will be shown
  //

  constructor (props) {
    super(props)
    this.state = {
    }
    this.handleCardClicked = this.cardClicked.bind(this)
  }

  componentDidMount () {

  }

  cardClicked () {
    // Only do this for north & south otherwise player
    // can play a card from bots hands
    if (this.props.hand === 'north' || this.props.hand === 'south') {
      this.props.cardClicked(this.props.hand, this.props.rank, this.props.suit)
    }
  }

  render () {
    var card = <P />
    var marginTop
    var marginLeft
    var mobileWidth
    var desktopWidth

    // Set default card widths for mobile and desktop views
    if (this.props.hand === 'south') {
      mobileWidth = 55
      desktopWidth = 94
    } else {
      mobileWidth = 45
      desktopWidth = 70
    }

    // Card height multiplier
    var cardHeightMultiplier = 1.4

    var mobileHeight = Math.floor(mobileWidth * cardHeightMultiplier)
    var desktopHeight = Math.floor(desktopWidth * cardHeightMultiplier)

    const spacing = this.props.spacing

    // Set up spacing between cards depending on whether the Hand is horizontal or vertical
    var mobileMarginLeft = -mobileWidth * (1.0 - spacing)
    var desktopMarginLeft = -desktopWidth * (1.0 - spacing)

    var mobileMarginTop = -mobileHeight * (1.0 - spacing)
    var desktopMarginTop = -desktopHeight * (1.0 - spacing)

    if (this.props.dummy) {
      switch (this.props.hand) {
        case 'north':
          marginTop = [-40, -75]
          marginLeft = [0, 0]
          break
        case 'east':
          marginTop = [-40, 0]
          marginLeft = [0, -42]
          break
        case 'south':
          marginTop = [-50, -85]
          marginLeft = [0, -20]
          break
        case 'west':
          marginTop = [-40, 0]
          marginLeft = [0, -42]
          break
      }
    } else {
      if (this.props.horizontalHand) {
        marginTop = [0, 0]
        marginLeft = [mobileMarginLeft, desktopMarginLeft]
      } else {
        marginTop = [mobileMarginTop, desktopMarginTop]
        marginLeft = [0, 0]
      }
    }

    // console.log('hand issue - Trying to create - rending', this.props.rank, this.props.suit)

    if (!this.props.visible) {
      card = (
        <Image
          sx={{
            width: [mobileWidth, desktopWidth],
            height: [mobileHeight, desktopHeight],
            marginTop: marginTop,
            marginLeft: marginLeft
          }}
          draggable={false} className={'card ' + (this.props.horizontalCard ? 'hcard ' : 'vcard ') + (this.props.hand)} src={require('./images/2B.svg')}
        />
      )
    } else if (this.props.rank && this.props.suit) {
      card = (
        <Image
          sx={{
            width: [mobileWidth, desktopWidth],
            height: [mobileHeight, desktopHeight],
            marginTop: marginTop,
            marginLeft: marginLeft
          }}
          draggable={false} id={this.props.id} className={'card ' + (this.props.hand || '') + ' ' + (this.props.table === 'true' ? 'table' : '') + ' ' + (this.props.horizontalCard ? 'hcard' : 'vcard')} src={require('./images/' + this.props.rank + this.props.suit + '.svg')} onClick={this.handleCardClicked}
        />
      )
    }

    return card
  }
}
export default Card
