// dependencies
import React from 'react'

import {
  Label as RebassLabel
} from '@rebass/forms'

// components
import Translate from 'App/components/text/Translate.js'

class Label extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    return (
      <RebassLabel {...this.props}>
        <Translate>{this.props.children}</Translate>
      </RebassLabel>
    )
  }
}
export default Label
