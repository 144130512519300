//
// Component: BotReplace
//
// Let the user choose whether to replace their partner with a bot
//

// dependencies
import React from 'react'

import { observer } from 'mobx-react'

import { Box, Flex } from 'rebass'

// components
import H1 from 'App/components/text/H1.js'
import Button from 'App/components/form/Button.js'

@observer
class BotReplace extends React.Component {
  //
  // props
  //
  // None at the moment but expect them to be fed through from GameStore
  //

  constructor (props) {
    super(props)
    this.state = {
    }
    this.replacePartnerWithBot = this.replacePartnerWithBot.bind(this)
    this.continueWaitingForPartner = this.continueWaitingForPartner.bind(this)
  }

  componentDidMount () {
    this.setState({
      error: false,
      errorMessage: ''
    })
  }

  /**
   * FUNCTION: replacePartnerWithBot
   *
   * Replaces the partner with a bot
   */

  replacePartnerWithBot () {
    this.props.replacePartnerWithBot()
  }

  continueWaitingForPartner () {
    this.props.continueWaitingForPartner()
  }

  render () {
    return (
      <>
        <Flex>
          <Box
            sx={{
              margin: '0 auto'
            }}
          >
            <H1 mb={3}>Your partner appears to have left the game. Do you want to replace their turn with a bot ?</H1>

            <Flex mb={3} flexDirection='column'>
              <Box mb={3}>
                <Button
                  sx={{
                    textTransform: 'uppercase',
                    backgroundColor: '#46282C',
                    borderRadius: 0,
                    cursor: 'pointer',
                    width: '100%'
                  }}
                  // eslint-disable-next-line react/jsx-handler-names
                  onClick={this.replacePartnerWithBot}
                >
                  Yes
                </Button>
              </Box>
              <Box>
                <Button
                  sx={{
                    textTransform: 'uppercase',
                    backgroundColor: '#46282C',
                    borderRadius: 0,
                    cursor: 'pointer',
                    width: '100%'
                  }}
                  // eslint-disable-next-line react/jsx-handler-names
                  onClick={this.continueWaitingForPartner}
                >
                  No
                </Button>
              </Box>
            </Flex>
          </Box>

        </Flex>

        {/* Error messages */}
        {this.state.error &&
          <Flex
            sx={{
              color: 'red',
              fontSize: [2]
            }}
          >
            {this.state.errorMessage}
          </Flex>}
      </>
    )
  }
}
export default BotReplace
