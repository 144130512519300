// dependencies
import React from 'react'

class RenderHTML extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    return (
      <span dangerouslySetInnerHTML={{ __html: this.props.HTML }} />
    )
  }
}
export default RenderHTML
