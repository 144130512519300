//
// Component: UndoRequestApproval
//
// Let the user choose whether to approve an undo request
//

// dependencies
import React from 'react'

import { observer } from 'mobx-react'

import { Box, Flex } from 'rebass'

// components
import H1 from 'App/components/text/H1.js'
import Button from 'App/components/form/Button.js'

@observer
class UndoRequestApproval extends React.Component {
  //
  // props
  //
  // None at the moment but expect them to be fed through from GameStore
  //

  constructor (props) {
    super(props)
    this.state = {
    }
    this.approveUndo = this.approveUndo.bind(this)
    this.denyUndo = this.denyUndo.bind(this)
  }

  componentDidMount () {
    this.setState({
      error: false,
      errorMessage: ''
    })
  }

  /**
   * FUNCTION: approveUndo
   *
   * Unod has been approved
   */

  approveUndo () {
    this.props.approveUndo()
  }

  denyUndo () {
    this.props.denyUndo()
  }

  render () {
    return (
      <>
        <Flex>
          <Box
            sx={{
              margin: '0 auto'
            }}
          >
            <H1 mb={3}>{this.props.undoRequestedBy} has requested to undo their turn. Do you approve?</H1>

            <Flex mb={3} flexDirection='column'>
              <Box mb={3}>
                <Button
                  sx={{
                    textTransform: 'uppercase',
                    backgroundColor: '#46282C',
                    borderRadius: 0,
                    cursor: 'pointer',
                    width: '100%'
                  }}
                  // eslint-disable-next-line react/jsx-handler-names
                  onClick={this.approveUndo}
                >
                  Yes
                </Button>
              </Box>
              <Box>
                <Button
                  sx={{
                    textTransform: 'uppercase',
                    backgroundColor: '#46282C',
                    borderRadius: 0,
                    cursor: 'pointer',
                    width: '100%'
                  }}
                  // eslint-disable-next-line react/jsx-handler-names
                  onClick={this.denyUndo}
                >
                  No
                </Button>
              </Box>
            </Flex>
          </Box>

        </Flex>

        {/* Error messages */}
        {this.state.error &&
          <Flex
            sx={{
              color: 'red',
              fontSize: [2]
            }}
          >
            {this.state.errorMessage}
          </Flex>}
      </>
    )
  }
}
export default UndoRequestApproval
