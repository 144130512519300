// dependencies
import React from 'react'

import {
  Input as RebassInput
} from '@rebass/forms'

// components
// import Translate from "App/components/text/Translate.js"

// TODO: translate the placeholder and value props

class Input extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    const propsOnChange = this.props.onChange || function () {}

    return (
      <RebassInput {...this.props} onChange={(e) => { propsOnChange(e.target.value) }} />
    )
  }
}
export default Input
