// dependencies
import React from 'react'
import { Heading } from 'rebass'

// components
import Translate from 'App/components/text/Translate.js'

class H1 extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    return (
      <Heading as='h1' {...this.props} fontSize={20}>
        <Translate>{this.props.children}</Translate>
      </Heading>
    )
  }
}
export default H1
