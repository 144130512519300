import React from 'react'

import Text from 'App/components/text/Text.js'

class P extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    return (
      <Text {...this.props}><p>{this.props.children}</p></Text>
    )
  }
}
export default P
