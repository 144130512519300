// dependencies
import React from 'react'
import { Flex } from 'rebass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

// components
import Text from 'App/components/text/Text.js'

/**
 *
 * Displays a bid like a Bridge bid card
 *
 */

class BidCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    var bidValue = this.props.bidValue
    var bidSuit = this.props.bidSuit
    var pass = this.props.pass
    var double = this.props.double
    var redouble = this.props.redouble
    var bg = pass === true ? '#41a437' : double === true ? '#dc2026' : redouble === true ? '#2a3382' : '#fff'
    var color = pass === true || double === true || redouble === true ? '#fff' : (bidSuit === 'hearts' || bidSuit === 'diamonds') ? '#df343a' : '#000'
    var cardText
    var animatedDirection = ''

    var suitName = {
      spades: 'spade',
      hearts: 'heart',
      diamonds: 'diamond',
      clubs: 'club',
      nt: 'NT',
      notrump: 'NT'
    }

    bidSuit = suitName[bidSuit]

    if (pass === true || double === true || redouble === true) {
      cardText =
        <Text
          sx={{
            fontSize: [1, 2],
            fontWeight: 500
          }}
        >
          {pass &&
            'pass'}
          {double &&
            'X'}
          {redouble &&
            'XX'}
        </Text>
    } else {
      cardText =
        <Text
          sx={{
            fontSize: [2, 4],
            fontWeight: 700
          }}
        >
          {bidValue}{bidSuit === 'NT' ? 'NT' : bidSuit ? <FontAwesomeIcon icon={['fas', bidSuit]} className={`color-${bidSuit}`} /> : ''}
        </Text>
    }

    if (this.props.player) {
      switch (this.props.player) {
        case 'north':
          animatedDirection = 'animated zoomInDown'
          break
        case 'east':
          animatedDirection = 'animated zoomInRight'
          break
        case 'south':
          animatedDirection = 'animated zoomInUp'
          break
        case 'west':
          animatedDirection = 'animated zoomInLeft'
          break
      }
    }

    return (
      <Flex
        {...this.props}
        sx={{
          bg: bg,
          color: color,
          justifyContent: 'center',
          alignItems: 'center',
          width: [this.props.small ? 30 : 40, 50],
          height: [this.props.small ? 30 : 40, 50],
          borderRadius: 8,
          borderStyle: pass === true || double === true || redouble === true ? 'none' : 'solid',
          borderColor: '#000',
          cursor: 'pointer'
        }}
        className={`${animatedDirection} ${this.props.className}`}
      >
        {cardText}
      </Flex>
    )
  }
}

BidCard.propTypes = {
  /** The value of the bid card 1-7 */
  bidValue: PropTypes.number,
  /** The suit of the bid card as a character ie. S, H, D, C */
  bidSuit: PropTypes.string,
  /** Has the Pass bid card been played ? True/False */
  pass: PropTypes.bool
}
export default BidCard
