//
// Component: SendMessage
//
// Send a message to a users
//

// dependencies
import React from 'react'

import { observer } from 'mobx-react'

import { Box, Flex } from 'rebass'

// components
import Button from 'App/components/form/Button.js'
import Select from 'App/components/form/Select.js'
import Input from 'App/components/form/Input.js'

@observer
class SendMessage extends React.Component {
  //
  // props
  //
  // None at the moment but expect them to be fed through from GameStore
  //

  constructor (props) {
    super(props)
    this.state = {
      messageRecipient: 'Table',
      messageToSend: '',
      error: false,
      errorMessage: ''
    }
    this.sendMessage = this.sendMessage.bind(this)
  }

  componentDidMount () {
    this.setState({
      error: false,
      errorMessage: ''
    })
  }

  /**
   * FUNCTION: sendMessage
   *
   * Send the message to the server
   */

  sendMessage () {
    if (!this.state.messageToSend) {
      this.setState({
        error: true,
        errorMessage: 'Please add a message'
      })
    } else {
      this.props.sendMessage(this.state.messageRecipient.toLowerCase(), this.state.messageToSend)
    }
  }

  render () {
    return (
      <>
        <Flex
          sx={{
            margin: '0 auto',
            flexDirection: 'column'
          }}
        >
          <Box>
            <Box width={1} mb={3}>
              <Input id='message-text' name='message-text' placeholder='Message' onChange={(input) => { this.setState({ messageToSend: input, error: false }) }} />
            </Box>

            {this.props.spectator &&
              <Box width={1} mb={3}>
                {/* eslint-disable-next-line react/jsx-handler-names */}
                <Select id='message-recipient' name='message-recipient' defaultValue='All' onChange={(event) => this.setState({ messageRecipient: event.target.value, error: false })}>
                  <option value='table'>Send to Table</option>
                  <option value='north'>Send to North</option>
                  <option value='east'>Send to East</option>
                  <option value='south'>Send to South</option>
                  <option value='west'>Send to West</option>
                </Select>
              </Box>}

            <Box mb={3}>
              <Button
                sx={{
                  textTransform: 'uppercase',
                  backgroundColor: '#46282C',
                  borderRadius: 0,
                  cursor: 'pointer',
                  width: '100%'
                }}
                // eslint-disable-next-line react/jsx-handler-names
                onClick={this.sendMessage}
              >
                Send
              </Button>
            </Box>
          </Box>
        </Flex>

        {/* Error messages */}
        {this.state.error &&
          <Flex
            sx={{
              color: 'red',
              fontSize: [2]
            }}
          >
            {this.state.errorMessage}
          </Flex>}
      </>
    )
  }
}
export default SendMessage
