//
// Component: ClaimGame
//
// Displays & controls the Claim Game screen
//

// dependencies
import React from 'react'

import { observer } from 'mobx-react'

import { Box, Flex } from 'rebass'

// components
import H1 from 'App/components/text/H1.js'
import Button from 'App/components/form/Button.js'

@observer
class ClaimGame extends React.Component {
  //
  // props
  //
  // None at the moment but expect them to be fed through from GameStore
  //

  constructor (props) {
    super(props)
    this.state = {
    }
    this.acceptClaimGame = this.acceptClaimGame.bind(this)
    this.denyClaimGame = this.denyClaimGame.bind(this)
  }

  componentDidMount () {
    this.setState({
    })
  }

  /**
   * FUNCTION: acceptClaimGame
   *
   * Opponent accepts you have claimed the game
   */

  acceptClaimGame () {
    this.props.acceptClaimGame()
  }

  /**
   * FUNCTION: denyClaimGame
   *
   * Opponent does not accept you have claimed the game
   */

  denyClaimGame () {
    this.props.denyClaimGame()
  }

  render () {
    return (
      <>
        <Flex>
          <Box
            sx={{
              margin: '0 auto'
            }}
          >
            <H1 mb={3}>The game has been claimed by your opponents. You can now see all players' hands to determine if the claim is valid. Do you accept this ?</H1>

            <Flex mb={3} flexDirection='column'>
              <Box mb={3}>
                <Button
                  sx={{
                    textTransform: 'uppercase',
                    backgroundColor: '#46282C',
                    borderRadius: 0,
                    cursor: 'pointer',
                    width: '100%'
                  }}
                  // eslint-disable-next-line react/jsx-handler-names
                  onClick={this.acceptClaimGame}
                >
                  Yes
                </Button>
              </Box>
              <Box>
                <Button
                  sx={{
                    textTransform: 'uppercase',
                    backgroundColor: '#46282C',
                    borderRadius: 0,
                    cursor: 'pointer',
                    width: '100%'
                  }}
                  // eslint-disable-next-line react/jsx-handler-names
                  onClick={this.denyClaimGame}
                >
                  No
                </Button>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </>
    )
  }
}
export default ClaimGame
