import { flow, observable, computed } from 'mobx'

import React from 'react'
import ApiService from 'App/services/ApiService'

// @observer
class AuthStore extends React.Component {
  // constructor (props) {
  //   super(props)
  //   this.api = new Api()
  // }

  @observable status = 'initial';

  @observable apiToken = undefined
  @observable userDetails = {}
  @observable userOrganisations = []

  @computed get getApiToken () {
    return this.apiToken
  }

  @computed get getUserName () {
    let name = ''
    if (this.userDetails) {
      if (this.userDetails.firstName) {
        name += `${this.userDetails.firstName}`

        if (this.userDetails.lastName) {
          name += ' '
        }
      }
      if (this.userDetails.lastName) {
        name += `${this.userDetails.lastName}`
      }
    }

    return name
  }

  logout () {
    this.apiToken = undefined
    this.userDetails = {}
    this.userOrganisations = {}
    this.status = 'initial'
  }

  login = flow(function * (username, password) {
    var response
    try {
      this.status = 'pending'
      response = yield ApiService.login(username, password)
      console.log('response', response)
      this.status = 'authenticated'

      let apiToken
      let userDetails = {}
      let userOrganisations = {}
      if (response.data) {
        apiToken = response.data.apiToken
        userDetails = response.data.userDetails
        userOrganisations = response.data.userOrganisations
      }

      this.apiToken = apiToken
      this.userDetails = userDetails
      this.userOrganisations = userOrganisations
    } catch (err) {
      console.error(err)
      this.status = 'error'
      this.apiToken = response.data.apiToken
      this.userDetails = response.data.userDetails
      this.userOrganisations = response.data.userOrganisations
    }

    return response
  });
}
export default new AuthStore()
