// dependencies
import React from 'react'
import { Text as RebassText } from 'rebass'

// components
import Translate from 'App/components/text/Translate.js'

class Text extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    return (
      <RebassText {...this.props}>
        <Translate>
          {this.props.children}
        </Translate>
      </RebassText>
    )
  }
}
export default Text
