//
// Component: NavBar
//
// Displays the navigation bar
//

// dependencies
import React from 'react'
import { observer } from 'mobx-react'
import { CSSTransition } from 'react-transition-group'

import { Flex, Box, Text } from 'rebass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// stores
import GameStore from 'App/stores/GameStore'

// components
import MenuButton from 'App/components/menu/MenuButton.js'
import MenuItem from 'App/components/menu/MenuItem.js'
import MessageModal from 'App/components/modal/MessageModal.js'

// import Translate from 'App/components/text/Translate.js'

// @observer
const NavBar = observer(class NavBar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      modalText: '',
      menuDisplayed: false
    }

    this.showMenu = this.showMenu.bind(this)
    this.hideMenu = this.hideMenu.bind(this)
    this.showModal = this.showModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.menuClicked = this.menuClicked.bind(this)
  }

  componentDidMount () {

  }

  //
  // Set the menuButton menuDisplayed state to true to display the menu
  //
  showMenu () {
    this.setState({ menuDisplayed: true }, () => {
      document.addEventListener('click', this.hideMenu)
    })
  }

  //
  // Set the menuButton menuDisplayed state to false to hide the menu
  //
  hideMenu () {
    this.setState({ menuDisplayed: false }, () => {
      document.removeEventListener('click', this.hideMenu)
    })
  }

  showModal () {
    this.setState({
      modalIsOpen: true
    })
  }

  closeModal () {
    this.setState({
      modalIsOpen: false
    })
  }

  menuClicked (menu) {
    this.props.menuClicked(menu)
  }

  render () {
    if (GameStore.bid) {
      var bidDenomination
      switch (GameStore.bid.denomination) {
        case 'spades':
          bidDenomination = <FontAwesomeIcon icon={['fas', 'spade']} />
          break
        case 'hearts':
          bidDenomination = <FontAwesomeIcon icon={['fas', 'heart']} />
          break
        case 'diamonds':
          bidDenomination = <FontAwesomeIcon icon={['fas', 'diamond']} />
          break
        case 'clubs':
          bidDenomination = <FontAwesomeIcon icon={['fas', 'club']} />
          break
        case 'nt':
        case 'notrump':
        case 'nottrump':
        case 'notrumps':
        case 'nottrumps':
          bidDenomination = 'NT'
          break
      }
    }

    return (
      <Flex
        sx={{
          p: 2,
          color: 'white',
          bg: '#492a2c',
          alignItems: 'center',
          // backgroundImage: 'url(https://source.unsplash.com/random/1024x768?sky)',
          // backgroundImage: 'url(../assets/img/200px-All-Things-Bridge.png)',
          backgroundImage: 'url(/200px-All-Things-Bridge.png)',
          // backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          borderRadius: 16,
          width: ['100%', 600, 700],
          top: 0,
          zIndex: 20,
          flexDirection: 'column',
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)'
        }}
      >
        <Flex
          sx={{
            flexDirection: 'row',
            width: '100%'
          }}
        >
          {/* Bid boxes */}
          <Flex
            sx={{
              py: 1,
              pr: 2,
              flexDirection: 'column',
              borderTop: 0,
              borderRight: 2,
              borderBottom: 0,
              borderLeft: 0,
              borderColor: 'white',
              borderStyle: 'solid',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                borderTop: 0,
                borderRight: 0,
                borderBottom: 2,
                borderLeft: 0,
                borderColor: 'white',
                borderStyle: 'solid',
                pb: 1
              }}
            >
              <Text p={2} fontWeight='bold' textAlign='center'>{GameStore.phase}</Text>
            </Box>
            <Box
              sx={{
                pt: 1
              }}
            >
              <Text p={2} textAlign='center'>
                {GameStore.bid ? GameStore.bid.level : ''}
                {bidDenomination}
                {GameStore.contractDouble ? 'X' : ''}
                {GameStore.contractDoubleDouble ? 'XX' : ''}
              </Text>
            </Box>
          </Flex>
          {/* Score boxes */}
          <Flex
            sx={{
              pl: 2,
              py: 1,
              flexDirection: 'column'
            }}
          >
            <Box>
              <Flex
                sx={{
                  flexDirection: 'row'
                }}
              >
                <Text p={1} fontSize='14px' fontWeight='bold'>NS: </Text>
                <Text p={1}>{GameStore.tricksWonByPartners.northsouth}</Text>
              </Flex>
            </Box>
            <Box>
              <Flex
                sx={{
                  flexDirection: 'row'
                }}
              >
                <Text p={1} fontSize='14px' fontWeight='bold'>EW: </Text>
                <Text p={1}>{GameStore.tricksWonByPartners.eastwest}</Text>
              </Flex>
            </Box>
            <Box>
              <Flex
                sx={{
                  flexDirection: 'row'
                }}
              >
                <Text p={1} fontSize='14px' fontWeight='bold'>Vulnerability: </Text>
                <Text p={1}>{GameStore.vulnerabilityMessage}</Text>
              </Flex>
            </Box>
          </Flex>
          <Box mx='auto' />
          {/* eslint-disable-next-line react/jsx-handler-names */}
          <MenuButton showMenu={this.showMenu} menuDisplayed={this.state.menuDisplayed} />
        </Flex>
        {/* Menu items */}
        {/* {
          this.state.menuDisplayed
            ? ( */}
        {/* eslint-disable-next-line react/jsx-handler-names */}
        <CSSTransition in={this.state.menuDisplayed} timeout={300} classNames='slide-down' unmountOnExit onEntered={this.showMenu} onExit={this.hideMenu}>
          <Flex
            sx={{
              flexDirection: 'column',
              width: '100%',
              pl: [1, 5],
              pr: [1, 5]
            }}
          >
            {/* eslint-disable-next-line react/jsx-handler-names */}
            <MenuItem menuClicked={this.menuClicked} menu={1}>Start or Join a Game</MenuItem>
            {/* eslint-disable-next-line react/jsx-handler-names */}
            {/* <MenuItem menuClicked={this.menuClicked} menu={2}>Bid History</MenuItem> */}
            {/* eslint-disable-next-line react/jsx-handler-names */}
            <MenuItem menuClicked={this.menuClicked} menu={3}>Game History</MenuItem>
            {GameStore.phase === 'play' &&
              <>
                <MenuItem menuClicked={this.menuClicked} menu={4}>Concede Game</MenuItem>
                <MenuItem menuClicked={this.menuClicked} menu={5}>Claim - coming soon</MenuItem>
              </>}
            {/* eslint-disable-next-line react/jsx-handler-names */}
            <MenuItem menuClicked={this.menuClicked} menu={6}>Exit Game</MenuItem>
          </Flex>
        </CSSTransition>
        {/* )
            : (
              null
            )
        } */}
        <MessageModal showModal={this.state.modalIsOpen} closeModal={this.closeModal}>
          <Text>{this.state.modalText}</Text>
        </MessageModal>
      </Flex>
    )
  }
})
export default NavBar
