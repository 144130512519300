//
// Component: TableBids
//
// Displays the players bids on the table
//

// dependencies
import React from 'react'
import { Box } from 'rebass'
import { observer } from 'mobx-react'

// stores
import GameStore from 'App/stores/GameStore'

// components
import BidCard from 'App/components/auction/BidCard.js'
// import MessageModal from 'App/components/modal/MessageModal.js'
// import LivePlayBid from 'App/components/auction/LivePlayBid.js'

@observer
class TableBids extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
    this.bidSelected = this.bidSelected.bind(this)
  }

  bidSelected (player, rank, suit, pass) {
    this.props.bidSelected(player, rank, suit, pass)
  }

  displayReason (reason) {
    if (reason) {
      alert(reason)
    }
  }

  render () {
    return (
      <Box>
        <Box
          sx={{
            position: 'fixed',
            display: 'flex',
            flexDirection: 'row-reverse',
            top: [135, 120],
            // left: '50%',
            left: 0,
            right: 0,
            margin: 'auto',
            // ml: [-20, -25],
            width: [180, 200],
            height: [180, 60]
          }}
        >
          {(GameStore.seats && GameStore.roundBids[GameStore.seats[2]].show) &&
            GameStore.roundBids[GameStore.seats[2]].bids.map(function (bid, index) {
              return (
                <BidCard key={index} player={GameStore.seats[2]} bidValue={bid.bidValue} bidSuit={bid.bidSuit} pass={bid.pass} double={bid.double} redouble={bid.redouble} onClick={() => { this.displayReason(bid.reason) }} />
              )
            }, this)}
          {/* {GameStore.bidTurn === 'north' &&
            <MessageModal showModal={GameStore.bidTurn === 'north'} closeModal={this.closeModal} closeWithOverlay={false} bottom='true'>
              <LivePlayBid player='north' bidSelected={this.bidSelected} />
            </MessageModal>} */}
        </Box>
        <Box
          sx={{
            position: 'fixed',
            display: 'flex',
            flexDirection: 'column-reverse',
            top: '50%',
            right: [40, 100],
            // mt: [-20, -25],
            transform: 'translateY(-50%)',
            width: [40, 60],
            height: [180, 200]
          }}
        >
          {(GameStore.seats && GameStore.roundBids[GameStore.seats[3]].show) &&
            GameStore.roundBids[GameStore.seats[3]].bids.map(function (bid, index) {
              return (
                <BidCard key={index} player={GameStore.seats[3]} bidValue={bid.bidValue} bidSuit={bid.bidSuit} pass={bid.pass} double={bid.double} redouble={bid.redouble} onClick={() => { this.displayReason(bid.reason) }} />
              )
            }, this)}
          {/* {GameStore.bidTurn === 'east' &&
            <MessageModal showModal={GameStore.bidTurn === 'east'} closeModal={this.closeModal} closeWithOverlay={false} bottom='true'>
              <LivePlayBid player='east' bidSelected={this.bidSelected} />
            </MessageModal>} */}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            bottom: GameStore.seats && GameStore.seats[0] === GameStore.bidTurn ? '40px' : [0, -10],
            // bottom: '-45px',
            // left: '50%',
            // ml: [-20, -25],
            left: 0,
            right: 0,
            margin: 'auto',
            width: [180, 200],
            height: [40, 60]
          }}
        >
          {(GameStore.seats && GameStore.roundBids[GameStore.seats[0]].show) &&
            GameStore.roundBids[GameStore.seats[0]].bids.map(function (bid, index) {
              return (
                <BidCard key={index} player={GameStore.seats[0]} bidValue={bid.bidValue} bidSuit={bid.bidSuit} pass={bid.pass} double={bid.double} redouble={bid.redouble} onClick={() => { this.displayReason(bid.reason) }} />
              )
            }, this)}
          {/* If the bid turn matches the player name then they must be in 'south's position */}
          {/* {GameStore.bidTurn === GameStore.playerSession.player &&
            <LivePlayBid player={GameStore.playerSession.player} bidSelected={this.bidSelected} />} */}

          {/* {GameStore.roundBids.south.show &&
            <BidCard bidValue={GameStore.roundBids.south.bidValue} bidSuit={GameStore.roundBids.south.bidSuit} pass={GameStore.roundBids.south.pass} onClick={() => { this.displayReason('south') }} />} */}
        </Box>
        <Box
          sx={{
            position: 'fixed',
            display: 'flex',
            flexDirection: 'column',
            top: '50%',
            left: [40, 110],
            // mt: [-20, -25],
            transform: 'translateY(-50%)',
            width: [180, 60],
            height: [180, 200]
          }}
        >
          {(GameStore.seats && GameStore.roundBids[GameStore.seats[1]].show) &&
            GameStore.roundBids[GameStore.seats[1]].bids.map(function (bid, index) {
              return (
                <BidCard key={index} player={GameStore.seats[1]} bidValue={bid.bidValue} bidSuit={bid.bidSuit} pass={bid.pass} double={bid.double} redouble={bid.redouble} onClick={() => { this.displayReason(bid.reason) }} />
              )
            }, this)}
          {/* {GameStore.bidTurn === 'west' &&
            <MessageModal showModal={GameStore.bidTurn === 'west'} closeModal={this.closeModal} closeWithOverlay={false} bottom='true'>
              <LivePlayBid player='west' bidSelected={this.bidSelected} />
            </MessageModal>} */}
        </Box>
      </Box>
    )
  }
}
export default TableBids
