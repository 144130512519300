//
// Component: MenuScenario
//
// Displays & controls the scenario selection menu
//

// dependencies
import React from 'react'

import { observer } from 'mobx-react'
import { Box, Flex } from 'rebass'

// components
import Text from 'App/components/text/Text.js'
import H1 from 'App/components/text/H1.js'
import H2 from 'App/components/text/H2.js'
import Input from 'App/components/form/Input.js'
import Select from 'App/components/form/Select.js'
import Button from 'App/components/form/Button.js'
import Label from 'App/components/form/Label.js'
import Pagination from 'App/components/pagination/Pagination.js'

// stores
import GameStore from '../../stores/GameStore'

@observer
class ChooseGame extends React.Component {
  //
  // props
  //
  // None at the moment but expect them to be fed through from GameStore
  //

  constructor (props) {
    super(props)
    this.state = {
      joiningSession: false
    }
    this.chooseJoinSession = this.chooseJoinSession.bind(this)
    this.filterScenarios = this.filterScenarios.bind(this)
    this.joinSession = this.joinSession.bind(this)
  }

  componentDidMount () {
    this.setState({
      error: false,
      errorMessage: '',

      gameType: null, // auction, full
      numHumans: null, // one, two, four
      handType: null, // random, scenario
      session: null,
      step: 'gameType',
      gameState: {},
      scenarioDifficulty: null,
      scenarioLimit: 10,
      scenarioPage: 1,
      stepHistory: [
        {
          step: 'gameType',
          gameState: {}
        }
      ],
      gameCodes: {
        northCode: null,
        eastCode: null,
        southCode: null,
        westCode: null
      },
      seat: {
        player: null,
        code: null
      },
      playerSession: {
        player: null,
        token: null
      },
      joiningSession: false
    })

    if (window.location.pathname.includes('/scenario/')) {
      setTimeout(() => {
        this.autoloadScenario()
      }, 500)
    }
  }

  startGame () {
    // scenario
    this.props.startSession(this.state.gameState.scenario, this.state.gameState.gameType, this.state.gameState.numHumans, this.state.gameState.randomType)
  }

  /**
   * FUNCTION: startSession
   *
   * Starts a session and gets the game codes required by all four players to join the session
   */

  /**
   * FUNCTION: chooseJoinSession
   *
   * User has chose to join a session
   */

  /**
  * FUNCTION: selectScenario
  *
  * User has selected a single scenario to play
  */

  autoloadScenario () {
    if (window.location.pathname.includes('/scenario/')) {
      const scenarioID = window.location.pathname.replace('/scenario/', '')
      var newState = this.state.gameState || {}
      newState.handType = 'scenario'
      newState.numHumans = 'one'
      newState.gameType = 'play'
      newState.scenario = scenarioID
      this.goToStep('startGame', newState)
    }
  }

  scenarioSelected (e) {
    e.preventDefault()
    var data = new FormData(e.target)

    var selectedScenario = data.get('scenario')
    this.goToStep('startGame', {
      scenario: selectedScenario
    })
  }

  handTypeSelect (data) {
    this.setState({
      gameState: {
        ...this.state.gameState,
        ...{ scenarioType: data }
      }
    })
  }

  goToStep (step, stateData = {}) {
    var revisedGameState = {
      ...this.state.gameState,
      ...stateData
    }

    console.log('TRACE - goToStep - ', step, revisedGameState)

    // override the handtype when a scenario is included in the url
    // if (step === 'handType') {
    //   if (window.location.pathname.includes('/scenario/')) {
    //     const scenarioID = window.location.pathname.replace('/scenario/', '')
    //     revisedGameState.handType = 'scenario'
    //     revisedGameState.scenario = scenarioID

    //     // go the number of players selection
    //     step = 'numPlayers'
    //     if (revisedGameState.numPlayers === 'one') {
    //       // go straight to the scenario
    //       step = 'startGame'
    //     }
    //   }
    // }

    var stepLog = {
      step,
      gameState: revisedGameState
    }

    var revisedStepHistory = this.state.stepHistory || []
    revisedStepHistory.push(stepLog)

    this.setState({
      step,
      gameState: revisedGameState,
      stepHistory: revisedStepHistory
    }, async () => {
      if (step === 'startGame') {
        await this.startGame()
      }
    })
  }

  goToPrevStep () {
    // get the previous step
    var currentStepHistory = this.state.stepHistory
    currentStepHistory.pop()

    var lastStep = currentStepHistory[currentStepHistory.length - 1]

    this.setState({
      step: lastStep.step,
      gameState: lastStep.gameState,
      stepHistory: currentStepHistory
    })
  }

  /**
   * FUNCTION: chooseJoinSession
   *
   * User has chose to join a session
   */

  chooseJoinSession () {
    this.setState({
      joiningSession: true
    })
  }

  /**
   * FUNCTION: joinSession
   *
   * Join a session using the codes provided from startSession
   */

  async joinSession () {
    if (!this.state.joiningCode || !this.state.playerName) {
      this.setState({
        error: true,
        errorMessage: 'Please enter a game code and your name'
      })
    } else {
      this.playerSession = await this.props.joinSession(this.state.joiningCode, this.state.playerName)

      if (this.playerSession && this.playerSession.error) {
        this.setState({
          error: true,
          errorMessage: this.playerSession.error
        })
      }
    }
  }

  /**
   * FUNCTION: setupScenarioFilters
   *
   * Use the form data entered by the user
   * to filter out the required scenarios
   */

  setupScenarioFilters (data) {
    var scenarioDifficulty = data.get('scenario-difficulty')
    var scenarioTitle = data.get('scenario-title')

    this.setState({
      scenarioDifficulty: scenarioDifficulty,
      scenarioTitle: scenarioTitle,
      scenarioPage: 1
    }, this.getScenarios)
  }

  /**
   * Get selected page of scenarios from the database
   */
  async getScenarios () {
    var scenarios = await GameStore.getScenarios(this.state.scenarioDifficulty, this.state.scenarioTitle, this.state.scenarioLimit, this.state.scenarioPage)
    if (scenarios.error) {
      this.setState({
        error: true,
        errorMessage: scenarios.error
      })
    } else {
      this.setState({
        error: false,
        errorMessage: ''
      })
      GameStore.convertToPBN(scenarios.data)

      // Set state to show all the scenarios
      this.setState({
        scenariosFound: true,
        scenarios: scenarios
      })
    }
  }

  /**
   * FUNCTION: filterScenarios
   *
   * User completes the Find Scenario form and
   * this function is called on hitting the Find button
   * and simply passes the form data onto the setupScenarioFilters
   * function
   */
  filterScenarios (e) {
    e.preventDefault()

    if (this.state.gameState.scenarioType === 'random') {
      this.startSession(null)
    } else {
      var data = new FormData(e.target)

      this.setupScenarioFilters(data)
    }
  }

  _renderStepGameType () {
    return (
      <Flex mb={3} flexDirection='column'>
        <H1 mb={3} style={{ textAlign: 'center' }}>What do you want to do today?</H1>
        <H2 mb={3} style={{ textAlign: 'center' }}>Start a new game</H2>
        <Box mb={3}>
          <Button
            sx={{
              textTransform: 'uppercase',
              backgroundColor: '#46282C',
              borderRadius: 0,
              cursor: 'pointer',
              width: '100%'
            }}
            onClick={() => { this.goToStep('auctionType', { numHumans: 'one', gameType: 'play' }) }}
          >
            On my own
          </Button>
        </Box>
        <Box mb={3}>
          <Button
            sx={{
              textTransform: 'uppercase',
              backgroundColor: '#46282C',
              borderRadius: 0,
              cursor: 'pointer',
              width: '100%'
            }}
            onClick={() => { this.goToStep('auctionType', { numHumans: 'two' }) }}
          >
            With 1 friend
          </Button>
        </Box>
        <Box mb={3}>
          <Button
            sx={{
              textTransform: 'uppercase',
              backgroundColor: '#46282C',
              borderRadius: 0,
              cursor: 'pointer',
              width: '100%'
            }}
            onClick={() => { this.goToStep('auctionType', { numHumans: 'four' }) }}
          >
            With 3 friends
          </Button>
        </Box>
        {/*
        <Box mb={3}>
          <Button
            sx={{
              textTransform: 'uppercase',
              backgroundColor: '#46282C',
              borderRadius: 0,
              cursor: 'pointer',
              width: '100%'
            }}
            onClick={() => { this.goToStep('handType', { gameType: 'auction' }) }}
          >
            Practice Bidding
          </Button>
        </Box>
        */}
        <br />
        <H2 mb={3} style={{ textAlign: 'center' }}>Join a game that someone else has started</H2>
        <Box>
          <Button
            sx={{
              textTransform: 'uppercase',
              backgroundColor: '#46282C',
              borderRadius: 0,
              cursor: 'pointer',
              width: '100%'
            }}
            onClick={() => { this.goToStep('joinGame') }}
          >
            Enter Join Code
          </Button>
        </Box>
      </Flex>
    )
  }

  _renderSelectedOptions () {
    var _toRender = []
    if (this.state?.gameState) {
      for (const key in this.state.gameState) {
        _toRender.push(<H2 mb={3}>{key}: {this.state.gameState[key]}</H2>)
      }
    }
    return (
      <Box mb={3}>
        {_toRender}
      </Box>
    )
  }

  _renderStepHandType () {
    var randomType = 'pure'
    if (this.state.gameState.numHumans === 'one') {
      randomType = 'fixed'
    }
    console.log('TRACE - ', this.state)

    return (
      <>

        <Flex mb={3} flexDirection='column'>

          <H1 mb={3}>What type of game?</H1>
          <Text fontSize={1} mb={1}>Select a random deck shuffle or choose from a scenario</Text>
          <Box mb={3}>
            {/* <Button
              sx={{
                textTransform: 'uppercase',
                backgroundColor: '#46282C',
                borderRadius: 0,
                cursor: 'pointer',
                width: '100%'
              }}
              onClick={() => { this.goToStep('numPlayers', { handType: 'random' }) }}
            >
              Play Regular Game
            </Button> */}
            <Button
              sx={{
                textTransform: 'uppercase',
                backgroundColor: '#46282C',
                borderRadius: 0,
                cursor: 'pointer',
                width: '100%'
              }}
              onClick={() => { this.goToStep('startGame', { handType: 'random', randomType }) }}
            >
              Random hand
            </Button>
          </Box>
          <Box mb={3}>
            <Button
              sx={{
                textTransform: 'uppercase',
                backgroundColor: '#46282C',
                borderRadius: 0,
                cursor: 'pointer',
                width: '100%'
              }}
              onClick={() => { this.goToStep('selectScenario', { handType: 'scenario' }) }}
            >
              Scenario
            </Button>
          </Box>
          <Box mb={3}>
            <Button
              sx={{
                textTransform: 'uppercase',
                textAlign: 'center',
                backgroundColor: '#222',
                borderRadius: 0,
                cursor: 'pointer',
                width: '50%'
              }}
              onClick={() => { this.goToPrevStep() }}
            >
              &lt; Back
            </Button>
          </Box>

        </Flex>
      </>
    )
  }

  _renderStepSelectScenario () {
    return (
      <Flex mb={3} flexDirection='column'>
        <H1>Select Scenario</H1>

        {!this.state.scenariosFound
          ? (
            <Box
              as='form'
              // eslint-disable-next-line react/jsx-handler-names
              onSubmit={this.filterScenarios}
            >
              <Box width={1}>
                <Label htmlFor='scenario-difficulty'>Difficulty</Label>
                <Select id='scenario-difficulty' name='scenario-difficulty' defaultValue='All'>
                  <option>All</option>
                  <option>Beginners</option>
                  <option>Improvers</option>
                  <option>Intermediates</option>
                  <option>Advanced</option>
                  <option>Uncategorized</option>
                </Select>
              </Box>

              <Box width={1}>
                <Label htmlFor='scenario-title'>Search</Label>
                <Input id='scenario-title' name='scenario-title' placeholder='Scenario Title' />
              </Box>

              <Box mb={3}>
                <Button
                  sx={{
                    textTransform: 'uppercase',
                    backgroundColor: '#46282C',
                    borderRadius: 0,
                    cursor: 'pointer',
                    width: '100%'
                  }}
                >
                  Find
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              as='form'
              onSubmit={(e) => { this.scenarioSelected(e) }}
            >
              <Box flexDirection='column' sx={{ display: 'flex' }}>
                <Flex mb={3} mt={3} justifyContent='flex-end'>
                  {/* eslint-disable-next-line react/jsx-handler-names */}
                  <Pagination totalRecords={this.state.scenarios.page.total} pageLimit={this.state.scenarioLimit} pageNeighbours={1} onPageChanged={this.onPageChanged} />
                </Flex>
                <Flex mb={3}>
                  {this.state.scenarios.data
                    ? (
                      <Select id='scenario' name='scenario'>
                        {Object.entries(this.state.scenarios.data).map(([key, scenario]) => (
                          <option
                            key={scenario.id}
                            value={scenario.id}
                          >
                            {scenario.title || 'Scenario ' + scenario.id}
                          </option>
                        ))}
                      </Select>
                    )
                    : (
                      <Box>
                        Waiting
                      </Box>
                    )}
                </Flex>

                <Flex mb={3}>
                  <Button
                    sx={{
                      textTransform: 'uppercase',
                      backgroundColor: '#46282C',
                      borderRadius: 0,
                      cursor: 'pointer',
                      width: '100%'
                    }}
                  >
                    Select
                  </Button>
                </Flex>
              </Box>
            </Box>
          )}

        <Box mb={3}>
          <Button
            sx={{
              textTransform: 'uppercase',
              textAlign: 'center',
              backgroundColor: '#222',
              borderRadius: 0,
              cursor: 'pointer',
              width: '50%'
            }}
            onClick={() => { this.goToPrevStep() }}
          >
            &lt; Back
          </Button>
        </Box>
      </Flex>
    )
  }

  _renderStepNumPlayers () {
    // check if the user has previously selected that this is a single player game

    if (this.state.gameState.numHumans === 'one') {
      // if we get here, just start the game, we have what we need
      this.goToStep('startGame')
    }

    return (
      <>

        <Flex mb={3} flexDirection='column'>

          <H1 mb={3}>How many human players?</H1>

          <Box mb={3}>
            <Button
              sx={{
                textTransform: 'uppercase',
                backgroundColor: '#46282C',
                borderRadius: 0,
                cursor: 'pointer',
                width: '100%'
              }}
              onClick={() => { this.goToStep('startGame', { numHumans: 'four' }) }}
            >
              4 Humans / 0 Bots
            </Button>
          </Box>
          <Box mb={3}>
            <Button
              sx={{
                textTransform: 'uppercase',
                backgroundColor: '#46282C',
                borderRadius: 0,
                cursor: 'pointer',
                width: '100%'
              }}
              onClick={() => { this.goToStep('startGame', { numHumans: 'two' }) }}
            >
              2 Humans / 2 Bots
            </Button>
          </Box>
          <Box mb={3}>
            <Button
              sx={{
                textTransform: 'uppercase',
                textAlign: 'center',
                backgroundColor: '#222',
                borderRadius: 0,
                cursor: 'pointer',
                width: '50%'
              }}
              onClick={() => { this.goToPrevStep() }}
            >
              &lt; Back
            </Button>
          </Box>

        </Flex>
      </>
    )
  }

  _renderAuctionType () {
    // check if user wants to play with a bid or no bid style game

    return (
      <>

        <Flex mb={3} flexDirection='column'>

          <H1 mb={3}>Would you like to play with bidding?</H1>

          <Box mb={3}>
            {this.state.gameState.numHumans === 'one' &&
              <Button
                sx={{
                  textTransform: 'uppercase',
                  backgroundColor: '#46282C',
                  opacity: 0.6,
                  borderRadius: 0,
                  width: '100%'
                }}
              >
                Structured Bidding (Coming Soon)
              </Button>}

          </Box>
          <Box mb={3}>
            {this.state.gameState.numHumans !== 'four' &&
              <Button
                sx={{
                  textTransform: 'uppercase',
                  backgroundColor: '#46282C',
                  opacity: 0.6,
                  borderRadius: 0,
                  width: '100%'
                }}
              >
                Free Bidding (Coming Soon)
              </Button>}

            {this.state.gameState.numHumans === 'four' &&
              <Button
                sx={{
                  textTransform: 'uppercase',
                  backgroundColor: '#46282C',
                  borderRadius: 0,
                  cursor: 'pointer',
                  width: '100%'
                }}
                onClick={() => { this.goToStep('handType', { gameType: 'auction' }) }}
              >
                Free Bidding
              </Button>}

          </Box>
          <Box mb={3}>
            <Button
              sx={{
                textTransform: 'uppercase',
                backgroundColor: '#46282C',
                borderRadius: 0,
                cursor: 'pointer',
                width: '100%'
              }}
              onClick={() => { this.goToStep('handType', { gameType: 'play' }) }}
            >
              No bidding
            </Button>
          </Box>
        </Flex>
      </>
    )
  }

  _renderJoinGame () {
    return (
      <Flex mb={3} flexDirection='column'>
        <H1 mb={3}>Enter your join code below</H1>
        <Flex mb={3} sx={{ display: this.state.joiningSession ? 'flex' : 'none', flexDirection: 'column' }}>
          <Box width={1}>
            <Label mt={1} htmlFor='game-code' fontSize={14} fontWeight={500}>Game Code</Label>
          </Box>
          <Box width={1}>
            <Input id='game-code' name='game-code' placeholder='Game Code' onChange={(input) => { this.setState({ joiningCode: input, error: false }) }} />
          </Box>
          <Box width={1}>
            <Label mt={1} htmlFor='player-name' fontSize={14} fontWeight={500}>Your Name</Label>
          </Box>
          <Box width={1}>
            <Input id='player-name' name='player-name' placeholder='Your Name' maxLength='10' onChange={(input) => { this.setState({ playerName: input, error: false }) }} />
          </Box>
          <Box mb={3}>
            <Button
              mt={1}
              sx={{
                textTransform: 'uppercase',
                backgroundColor: '#46282C',
                borderRadius: 0,
                cursor: 'pointer',
                width: '100%'
              }}
              onClick={() => { this.joinSession() }}
            >
              Join
            </Button>
          </Box>
          <Box mb={3}>
            <Button
              sx={{
                textTransform: 'uppercase',
                textAlign: 'center',
                backgroundColor: '#222',
                borderRadius: 0,
                cursor: 'pointer',
                width: '50%'
              }}
              onClick={() => { this.goToStep('gameType') }}
            >
              &lt; Back
            </Button>
          </Box>
        </Flex>
      </Flex>
    )
  }

  _renderCurrentStep () {
    var _view = null
    switch (this.state.step) {
      // game type
      case 'gameType': _view = this._renderStepGameType(); break
      // num players
      case 'numPlayers': _view = this._renderStepNumPlayers(); break
      // auction or bid type
      case 'auctionType': _view = this._renderAuctionType(); break
      // hand type
      case 'handType': _view = this._renderStepHandType(); break
      // (optional) select scenario
      case 'selectScenario': _view = this._renderStepSelectScenario(); break
      // join game
      case 'joinGame': _view = this._renderJoinGame(); break
    }
    return _view
  }

  render () {
    return (
      <>
        <Flex>
          <Box
            sx={{
              margin: '0 auto'
            }}
          >
            {/* this._renderSelectedOptions() */}
            {this._renderCurrentStep()}
          </Box>

        </Flex>

        {/* Error messages */}
        {this.state.error &&
          <Flex
            sx={{
              color: 'red',
              fontSize: [2]
            }}
          >
            {this.state.errorMessage}
          </Flex>}
      </>
    )
  }
}
export default ChooseGame
