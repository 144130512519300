// dependencies
import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { Box, Button, Flex } from 'rebass'
import { observer } from 'mobx-react'

// components
import BidCard from 'App/components/auction/BidCard.js'
import Text from 'App/components/text/Text.js'

// stores
// import GameStore from 'App/stores/GameStore'

/**
 *
 * Displays the bid options allowed
 * giving you the choice of one to play
 *
 */
@observer
class LivePlayBid extends React.Component {
  //
  // props
  //
  // player - north, south, east, west
  //

  constructor (props) {
    super(props)
    this.state = {
      bidValue: false,
      bidSuit: false,
      bidPass: false,
      bidText: '',
      bidReason: '',
      correctOptionSelected: false,
      bidSelected: false,
      bidError: ''
    }
    this.bidSelected = this.bidSelected.bind(this)
  }

  componentDidMount () {

  }

  async bidSelected () {
    var bidResult = await this.props.bidSelected(this.props.player, this.state.bidValue, this.state.bidSuit, this.state.bidPass, this.state.bidDouble, this.state.bidRedouble)

    if (bidResult) {
      this.setState({
        bidError: bidResult
      })
    }
  }

  // Set the bid state depending on user selection
  selectBid (bidValue, bidSuit, bidOther) {
    this.setState({
      bidError: ''
    })

    if (bidValue) {
      this.setState({
        bidValue: bidValue,
        bidPass: false,
        bidDouble: false,
        bidRedouble: false
      }, this.checkBidMade)
    }

    if (bidSuit) {
      this.setState({
        bidSuit: bidSuit,
        bidDouble: false,
        bidRedouble: false,
        bidPass: false
      }, this.checkBidMade)
    }

    if (bidOther === 'pass') {
      this.setState({
        bidDouble: false,
        bidRedouble: false,
        bidPass: true,
        bidValue: false,
        bidSuit: false
      }, this.checkBidMade)
    } else if (bidOther === 'double') {
      this.setState({
        bidDouble: true,
        bidRedouble: false,
        bidPass: false,
        bidValue: false,
        bidSuit: false
      }, this.checkBidMade)
    } else if (bidOther === 'redouble') {
      this.setState({
        bidDouble: false,
        bidRedouble: true,
        bidPass: false,
        bidValue: false,
        bidSuit: false
      }, this.checkBidMade)
    }
  }

  // If a bid is complete set bid selected
  checkBidMade () {
    if ((this.state.bidValue && this.state.bidSuit) || this.state.bidPass || this.state.bidDouble || this.state.bidRedouble) {
      var bidText = ''
      if (this.state.bidPass) {
        bidText = 'pass'
      } else if (this.state.bidDouble) {
        bidText = 'double'
      } else if (this.state.bidRedouble) {
        bidText = 'redouble'
      } else {
        bidText = this.state.bidValue + ' ' + (this.state.bidSuit === 'nt' ? 'no trumps' : this.state.bidSuit)
      }

      this.setState({
        bidSelected: true,
        bidText: bidText
      })
    } else {
      this.setState({
        bidSelected: false
      })
    }
  }

  render () {
    const bidTable = []

    const bidValueRow1 = [1, 2, 3, 4]
    const bidValueRow2 = [5, 6, 7]
    const bidSuit = ['clubs', 'diamonds', 'hearts', 'spades', 'nt']

    // eslint-disable-next-line no-unused-vars
    var bidRow = []
    for (const [index, value] of bidValueRow1.entries()) {
      bidRow.push(<td key={`${value}-${index}`}><BidCard small className={`bid ${this.state.bidValue === value ? 'selected' : null}`} bidValue={value} alignSelf='center' onClick={() => { this.selectBid(value, false, false) }} /></td>)
    }

    bidTable.push(<tr key='row-1'>{bidRow}</tr>)

    // eslint-disable-next-line no-unused-vars
    bidRow = []
    for (const [index, value] of bidValueRow2.entries()) {
      bidRow.push(<td key={`${value}-${index}`}><BidCard small className={`bid ${this.state.bidValue === value ? 'selected' : null}`} bidValue={value} alignSelf='center' onClick={() => { this.selectBid(value, false, false) }} /></td>)
    }

    bidTable.push(<tr key='row-2'>{bidRow}</tr>)

    // eslint-disable-next-line no-unused-vars
    bidRow = []
    for (const [index, suit] of bidSuit.entries()) {
      bidRow.push(<td key={`${index}-${suit}`}><BidCard small className={`bid ${this.state.bidSuit === suit ? 'selected' : null}`} bidSuit={suit} alignSelf='center' onClick={() => { this.selectBid(false, suit, false) }} /></td>)
    }

    bidTable.push(<tr key='row-3'>{bidRow}</tr>)

    bidTable.push((
      <tr key='row-4'>
        <td key='pass'><BidCard small className={`bid ${this.state.bidPass ? 'selected' : null}`} pass alignSelf='center' onClick={() => { this.selectBid(null, null, 'pass') }} /></td>
        <td key='double'><BidCard small className={`bid ${this.state.bidDouble ? 'selected' : null}`} double alignSelf='center' onClick={() => { this.selectBid(null, null, 'double') }} /></td>
        <td key='redouble'><BidCard small className={`bid ${this.state.bidRedouble ? 'selected' : null}`} redouble alignSelf='center' onClick={() => { this.selectBid(null, null, 'redouble') }} /></td>
      </tr>
    ))

    return (
      <>
        <Flex
          sx={{
            flexDirection: 'column',
            position: 'absolute',
            top: [35, 60],
            left: '50%',
            marginLeft: [-112, -175],
            marginTop: [0, -80]
            // justifyContent: 'center',
            // alignItems: 'center',
            // margin: '0 auto'
          }}
        >
          <Flex
            sx={{
              height: [225, 330],
              width: [225, 350],
              backgroundColor: '#fff',
              borderRadius: '16px',
              borderTop: '4px',
              borderRight: '4px',
              borderBottom: '4px',
              borderLeft: '4px',
              borderColor: '#492a2c',
              borderStyle: 'solid',
              zIndex: 20,
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Scrollbars>
              <table className='liveplay-bid-table'>
                <tbody>
                  {bidTable}
                </tbody>
              </table>
            </Scrollbars>
            <Box
              sx={{
                fontSize: 2,
                fontWeight: 500,
                marginTop: [1],
                marginBottom: [1],
                color: '#000'
              }}
            >
              {this.state.bidSelected &&
                <>
                  <Text>Bid Chosen - {this.state.bidText} <span style={{ color: 'red' }}>{this.state.bidError}</span></Text>
                </>}
            </Box>
            <Button
              sx={{
                textTransform: 'uppercase',
                backgroundColor: '#46282C',
                borderRadius: 0,
                alignSelf: 'stretch',
                cursor: 'pointer'
              }}
              // eslint-disable-next-line react/jsx-handler-names
              onClick={this.bidSelected}
              //   disabled={!this.state.correctOptionSelected}
            >
              {!this.state.bidSelected ? <>Choose a bid</> : <>continue</>}
            </Button>
          </Flex>
        </Flex>
      </>
    )
  }
}
export default LivePlayBid
