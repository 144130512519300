// dependencies
import React from 'react'

// TODO: Add translation

class Translate extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    return (this.props.children)
  }
}
export default Translate
