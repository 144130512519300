//
// An individual menu item
//
// Call with:
// <MenuItem onClick={this.menuClicked} menu={1}>menu text</MenuItem>
//
// where:
// menu text = menu item text
// menu={1} = a number to identify the menu
// onClick={this.menuClicked} = menuClicked is a function in the parent
//                              that works out which menu item has been
//                              selected and carries out the function

import React from 'react'

import { Box, Text } from 'rebass'

class MenuItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}

    this.menuClicked = this.menuClicked.bind(this)
  }

  componentDidMount () {

  }

  menuClicked () {
    this.props.menuClicked(this.props.menu)
  }

  render () {
    return (
      <Box
        sx={{
          paddingTop: [2, 3],
          paddingBottom: [1, 1],
          borderBottom: 'solid',
          borderColor: '#fff',
          borderWidth: 1,
          fontWeight: 700,
          cursor: 'pointer'
        }}
        // eslint-disable-next-line react/jsx-handler-names
        onClick={this.menuClicked}
      >
        <Text>{this.props.children}</Text>
      </Box>
    )
  }
}
export default MenuItem
